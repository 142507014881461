import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProviderService } from "../../provider/provider.service";
import { ProviderEINService } from "../../provider/provider-ein/provider-ein.service";
import { CompanyPacket } from "../../model/net/company-packet";
import { CompanyService } from "../../company/company.service";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";

@Component({
  selector: 'app-name-search-prompt',
  templateUrl: './name-search-prompt.component.html',
  styleUrls: ['./name-search-prompt.component.scss']
})
export class NameSearchPromptComponent implements OnInit {

  @Input()
  searchMode: string = "UB04_PROVIDER";

  @Input()
  selectMode: string = "MULTI_SELECT";

  // Fires when the user selects a provider from the search results list and the screen is in
  // UB04_PROVIDER mode.
  ub04ProviderSelected: BehaviorSubject<any> = new BehaviorSubject(null);
  ub04PayerSelected: BehaviorSubject<any> = new BehaviorSubject(null);

  formGroup!: FormGroup;
  searchResults: any [] = [];
  displayedColumns: any [] = ["id", "name"];

  // For use in UB04_PROVIDER mode
  existingNpi: string[] = [];

  federalTaxNumber: String = "";

  constructor(
    private providerService: ProviderService,
    private providerEINService: ProviderEINService,
    private companyService: CompanyService,
    private snack: FennecSnackbarService,
  ) {
    this.formGroup = new FormGroup({
    });
  }

  createFormGroup() {
    const newFormGroup = new FormGroup({});

    newFormGroup.addControl("name", new FormControl());

    if (this.searchMode === "PROVIDER" || this.searchMode === "UB04_PROVIDER") {
      newFormGroup.addControl("firstName", new FormControl());
      newFormGroup.addControl("lastName", new FormControl());
      newFormGroup.addControl("npiNumber", new FormControl());
    }

    return newFormGroup;
  }

  ngOnInit(): void {
    if (this.searchMode === "UB04_PROVIDER") {
      this.displayedColumns = ["id", "name/npi", "type", "firstName", "lastName", "address", "ub04ProviderActions"];
    }

    if(this.searchMode === "UB04_PAYER") {
      this.displayedColumns = ["id", "name", "healthCarePlanName", "ub04PayerActions"];
    }

    this.formGroup = this.createFormGroup();
  }

  onSearch() {
    if (this.searchMode === "PROVIDER" || this.searchMode === "UB04_PROVIDER") {
      this.providerSearch();
    }

    if(this.searchMode === "COMPANY" || this.searchMode === "UB04_PAYER") {
      this.companySearch();
    }
  }

  onClearSearch() {
    this.existingNpi = [];
    this.searchResults = [];
    this.formGroup = this.createFormGroup();
  }

  providerSearch() {
    this.providerService.getProviderNameSearch(
        this.formGroup.controls['name'].value,
        this.formGroup.controls['firstName'].value,
        this.formGroup.controls['lastName'].value,
        this.formGroup.controls['npiNumber'].value,
        0, 9999
      ).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.searchResults = response.data;

        this.searchResults.forEach((r:any) => {
          if(r.id > 0) {
            this.existingNpi.push(r.npiNumber);
          }
        });
      }
    });
  }

  companySearch() {
    this.companyService.getCompaniesByNameIncludingHCPlanName(
        this.formGroup.controls['name'].value,
        0, 9999
      ).subscribe(response => {
      if (response.hasErrors) {
        if (response.consolidatedErrorMessage === undefined) {
          response.consolidatedErrorMessage = "Unknown Error";
        }
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.searchResults = [];
        let idx = 0;
        for (idx = 0; idx < response.data.length; idx++) {
          let element = {
            id: response.data[idx].id,
            name: response.data[idx].name,
            healthCarePlanName: response.data[idx].healthCarePlanName
          };
          this.searchResults.push(element);
        }
      }
    });
  }

  onSelect(row: any) {
  }

  onUB04ProviderSelected(id: number, ub04ProviderType: string) {
    let msgObj = {
      id: id,
      ub04ProviderType: ub04ProviderType
    }
    this.onClearSearch();
    this.ub04ProviderSelected.next(msgObj);
  }

  selectUB04Provider(provider: any) {

    let msgObj = {
      id: provider.id,
      provider:provider,
      ub04ProviderType: this.selectMode
    }
    this.onClearSearch();
    this.ub04ProviderSelected.next(msgObj);
  }

  selectUB04Payer(company: CompanyPacket) {
    let msgObj = {
      id: company.id,
      name: company.name,
      addressId: company.addressId,
      ub04PayerType: this.selectMode
    }
    this.onClearSearch();
    this.ub04PayerSelected.next(msgObj);
  }

  doesExternalNPIExistOnList(provider:any) {
    let result = false;
    if(provider.id === -1){
      this.existingNpi.forEach((npiString) => {
        if(npiString == provider.npiNumber) {
          result = true;
          return;
        }
      })
    }
    return result;
  }

  getRowStyle(row:any) {
    if(row.id == -1) {
      return {
        "background-color": "rgba(185, 185, 185, 0.3)"
      }
    }
    return {}
  }

  providerEINSearch = () => {
    const payload = {
      federalTaxNumber: this.federalTaxNumber
    }

    this.providerEINService.searchProviderByFederalTaxNumber(payload, 0, 999).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage ?? "Undefined Error Message");
      } else {
        this.searchResults = response.data;
        // this.totalRowCount = response['totalRowCount'];
      }
    })
  }

  test = (asdf) => {
    console.log(asdf);
  }
}
