<mat-dialog-content class="content-wrapper alt-theme">
    <h1 mat-dialog-title>Itemized Line Item Adjustment</h1>
    <div class="line-item-adj-dialog-sub-heading">
      {{liServiceDateString}} {{liDescription}}
    </div>
    <div class="adj-form">
      <form [formGroup]="formGroup" class="container">
        <!-- Please note that the 'Allowed' label is a customer requested change. Please preserve the label. -->
        <mat-form-field class="adjusted-units">
          <mat-label>Allowed Units</mat-label>
          <input type="number" matInput placeholder="Allowed Units" formControlName="adjustedUnits">
        </mat-form-field>

        <!-- Please note that the 'Allowed' label is a customer requested change. Please preserve the label. -->
        <mat-form-field class="adjusted-amount">
          <mat-label>Allowed Amount</mat-label>
          <input type="number" matInput placeholder="Allowed Amount" formControlName="adjustedAmount">
        </mat-form-field>
        
        <button mat-stroked-button class="zero-allowed-amounts-button" (click)="zeroAllowedAmounts()">
          Zero Allowed Amounts
        </button>        
        
        <div>
          <mat-form-field appearance="outline" class="code-input">
            <mat-label>Adjustment Reason Code</mat-label>
            <input #adjustmentReasonCode matInput formControlName="adjustmentReasonCode">
          </mat-form-field>

          <mat-form-field appearance="fill" class="code-description">
            <mat-label>Adjustment Reason Code Description</mat-label>
            <input matInput formControlName="adjustmentReasonCodeDescription">
            <mat-hint style="color:red;" *ngIf="internalReasonCode"><mat-icon>report</mat-icon>This code is marked as Internal (not public) and this adjustment will not appear on reports</mat-hint>
          </mat-form-field>
        </div>

        <div style="margin-top: 5px;">
          <mat-form-field appearance="outline" class="code-input">
            <mat-label>Adjustment Explanation Code</mat-label>
            <input matInput formControlName="adjustmentExplanationCode">
          </mat-form-field>

          <mat-form-field appearance="fill" class="code-description">
            <mat-label>Adjustment Explanation Code Description</mat-label>
            <input matInput formControlName="adjustmentExplanationCodeDescription">
            <mat-hint style="color:red;" *ngIf="internalExplanationCode"><mat-icon>report</mat-icon>This code is marked as Internal (not public) and this adjustment will not appear on reports</mat-hint>
          </mat-form-field>
        </div>
      </form>
    </div>

    <app-add-comment [hidden]="!addCommentVisible" mode="MULTI" relationalType="ITEMIZED_LINE_ITEM" [relatedIdList]="ids" [embeddedInDialog]="true"></app-add-comment>
  </mat-dialog-content>
  
  <mat-dialog-actions class="fennec-controls alt-theme">
    <button mat-stroked-button (click)="toggleEmbeddedComments()">
      <mat-icon>{{addCommentVisible ?  "comments_disabled" : "add_comment"}}</mat-icon>
      {{addCommentVisible ? "Close Comment" : "Add Comment"}}
    </button>

    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Save
    </button>
    
    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
  </mat-dialog-actions>
  
  
