import { Component, Input, Optional, Self } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatHint } from '@angular/material/form-field';

@Component({
  selector: 'lib-xf-form-field',
  templateUrl: './xf-form-field.component.html',
  styleUrl: './xf-form-field.component.scss',
viewProviders:[{ provide: ControlContainer, useExisting: FormGroupDirective }]
})

/**
 * Wrapper component for Mat Form Field.
 * Handles UI for label, hint, error and length validations.
 * Operates like a normal MatFormField.  Must be a child of a form with a FormGroup, 
 * as well as a FormControl in the FormGroup that corresponds with this components' 
 * fromControlName argument.
 * Validations must be set up in the form control like a normal MatFormField.
 */
export class XFFormField {

  /**
   * maxLength input is a required input for the input length indicator functionality, 
   * but the component will work without it if this variable is left alone.
   */
  @Input()
  maxLength:number = -1;

  @Input()
  formControlName:string;

  @Input()
  labelText:string = "Default Label Text";

  @Input()
  warningDifference:number = 5;

  @Input()
  hint:string = null;

  @Input()
  width:number;

  @Input()
  appearance: "outline"| "fill" | null = null;

  @Input()
  type: "text" | "number" = "text";

  @Input()
  expectedPattern:string;

  onChange: any = () => {};

  onTouch: any = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }


  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


  writeValue(value: any): void {}

  test = () => {
    console.log(this.ngControl);
  }

  getErrorMessage = () => {
    const errors = this.ngControl?.errors;

    if (errors == null) {
      return "";
    }

    if (errors['required']) {
      return "Required";

    }else if (errors['maxlength']) {
      return "Max Length Exceeded";

    }else if (errors['minlength']) {
      return "Minimum length not met"

    }else if (errors['incorrect']) {
      return "Invalid"
    }else if (errors['pattern']) {
      if (this.expectedPattern != null) {
        if (this.expectedPattern.length == this.ngControl?.value.length) {
          return `Invalid (${this.expectedPattern})`;
        }
        return "";
      }
      
      return "Unexpected Format"
    }

    return "Invalid";
  }


}
