import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject } from 'rxjs';
import { BaseResponse } from "../../../model/base-response";
import { UB04PayerPacket } from "../../../model/net/ub04-payer-packet";
import { CodeValidationService } from "../../../code/code-validation.service";
import { CompanyService } from "../../../company/company.service";
import { FennecSnackbarService } from "../../../dialog/fennec-snackbar/fennec-snackbar.service";

@Component({
  selector: 'app-ub04-edit-payer-component',
  templateUrl: './ub04-edit-payer-component.component.html',
  styleUrls: ['./ub04-edit-payer-component.component.scss']
})
export class Ub04EditPayerComponentComponent implements OnInit {

  formGroup!: FormGroup;

  existingUB04Payer!: UB04PayerPacket | null;

  selectedCompany: any = null;

  companySearch: BehaviorSubject<any> = new BehaviorSubject(null);
  deletePayer: BehaviorSubject<any> = new BehaviorSubject(null);

  // PRIMARY, SECONDARY, or TERTIARY
  @Input()
  payerType: string = "PRIMARY";

  maxLength = {
    default:50,
    insuredGroupName: 50,
    insuredGroupNo: 50,
    insuredUniqueIdentNo: 50,
  }

  constructor(
    private codeValidationService: CodeValidationService,
    private companyService: CompanyService,
    private snack: FennecSnackbarService
  ) {
    this.constructFormGroup();
  }

  ngOnInit(): void {
  }

  onCompanySearch() {
    this.companySearch.next(this.payerType);
  }

  constructFormGroup() {
    this.formGroup = new FormGroup({
      releaseInfoCertification: new FormControl(),
      assignmentBenefitCertification: new FormControl(),
      priorPayments: new FormControl(),
      estimatedAmountDue: new FormControl(""),
      insuredFirstName: new FormControl("", Validators.required),
      insuredLastName: new FormControl("", Validators.required),
      insuredUniqueIdentNo: new FormControl("", [Validators.required, Validators.maxLength(this.maxLength.insuredUniqueIdentNo)]),
      insuredGroupName: new FormControl("", Validators.maxLength(this.maxLength.insuredGroupName)),
      insuredGroupNo: new FormControl("", Validators.maxLength(this.maxLength.insuredGroupNo)),
      treatmentAuthorizationCode: new FormControl(),
      documentControlNo: new FormControl(),
      employerName: new FormControl(),
      patientRelationshipCode: new FormControl(""),
      patientRelationshipCodeDescription: new FormControl(),
    });

    this.formGroup.controls['patientRelationshipCodeDescription'].disable();
    this.formGroup.controls['patientRelationshipCode'].valueChanges.subscribe((val) => {
      this.validatePatientRelationshipCode(val);
    })
  }

  onDelete() {
    if(this.existingUB04Payer != null) {
      this.existingUB04Payer.deleteRow = true;
    }

    const msgObj = {
      existingPayer: this.existingUB04Payer !== null ? this.existingUB04Payer : null,
      payerType: this.payerType
    }

    this.deletePayer.next(msgObj);
  }

  validatePatientRelationshipCode(value: any) {
    let prcControl = this.formGroup.controls['patientRelationshipCode'];
    let prcDescriptionControl = this.formGroup.controls['patientRelationshipCodeDescription'];

    if(!prcControl.pristine){
      if(value.length > 0) {
        this.codeValidationService.getPatientRelationshipCode(value).subscribe((response: BaseResponse) => {
          if(response.hasErrors) {
            prcControl.markAsPristine();
            prcControl.setErrors({'incorrect': true});
          }else{
            if(response.data !== null) {
              prcControl.markAsPristine();
              prcDescriptionControl.setValue(response.data.description);
              prcControl.setErrors(null);
            }else {
              prcDescriptionControl.setValue("Invalid");
              prcControl.markAsPristine();
              prcControl.setErrors({'incorrect': true});
            }
          }
        })
      }else{
        prcDescriptionControl.setValue("");
      }
    }
  }

  populateExistingProvider(existingPayer: UB04PayerPacket) {
    this.existingUB04Payer = existingPayer;

    this.companyService.getCompany(existingPayer.companyId).subscribe((response: BaseResponse) => {
      if(response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      }else {
        this.selectedCompany = response.data
      }
    })

    const controls = this.formGroup.controls;
    controls['releaseInfoCertification'].setValue(existingPayer.releaseInfoCertification);
    controls['assignmentBenefitCertification'].setValue(existingPayer.assignmentBenefitCertification);
    controls['priorPayments'].setValue(existingPayer.priorPayments);
    controls['estimatedAmountDue'].setValue(existingPayer.estimatedAmountDue);
    controls['patientRelationshipCode'].setValue(existingPayer.patientRelationshipCode);
    controls['patientRelationshipCodeDescription'].setValue(existingPayer.patientRelationshipCodeDescription);
    controls['documentControlNo'].setValue(existingPayer.documentControlNo);
    controls['employerName'].setValue(existingPayer.employerName);
    controls['insuredFirstName'].setValue(existingPayer.insuredFirstName);
    controls['insuredLastName'].setValue(existingPayer.insuredLastName);
    controls['insuredUniqueIdentNo'].setValue(existingPayer.insuredUniqueIdentNo);
    controls['insuredGroupNo'].setValue(existingPayer.insuredGroupNo);
    controls['insuredGroupName'].setValue(existingPayer.insuredGroupName);
    controls['treatmentAuthorizationCode'].setValue(existingPayer.treatmentAuthorizationCode);
  }

  populateFormGroupFromExistingFormGroup(existingFormGroup: FormGroup) {
    const controls = this.formGroup.controls;
    const efControls = existingFormGroup.controls;
    controls['releaseInfoCertification'].setValue(efControls['releaseInfoCertification'].value);
    controls['assignmentBenefitCertification'].setValue(efControls['assignmentBenefitCertification'].value);
    controls['priorPayments'].setValue(efControls['priorPayments'].value);
    controls['estimatedAmountDue'].setValue(efControls['estimatedAmountDue'].value);
    controls['patientRelationshipCode'].setValue(efControls['patientRelationshipCode'].value);
    controls['patientRelationshipCodeDescription'].setValue(controls['patientRelationshipCodeDescription'].value);
    controls['documentControlNo'].setValue(efControls['documentControlNo'].value);
    controls['employerName'].setValue(efControls['employerName'].value);
    controls['insuredFirstName'].setValue(efControls['insuredFirstName'].value);
    controls['insuredLastName'].setValue(efControls['insuredLastName'].value);
    controls['insuredUniqueIdentNo'].setValue(efControls['insuredUniqueIdentNo'].value);
    controls['insuredGroupNo'].setValue(efControls['insuredGroupNo'].value);
    controls['insuredGroupName'].setValue(efControls['insuredGroupName'].value);
    controls['treatmentAuthorizationCode'].setValue(efControls['treatmentAuthorizationCode'].value);
  }

  formattedPayerType(): string {
    if(this.payerType === "PRIMARY") {
      return "Primary"
    }

    if(this.payerType === "SECONDARY") {
      return "Secondary"
    }

    if(this.payerType === "TERTIARY") {
      return "Tertiary"
    }

    return "Other"
  }
}
