import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { EnvironmentService } from "../environment.service";
import { BaseResponse } from "../model/base-response";
import { UB04RemarksUpdatePacket } from '../model/net/ub04-remarks-update-packet';
import { UB04Create } from "../model/net/ub04-create";
import { UB04Edit1 } from "../model/net/ub04-edit";
import { UB04RevenueCodeBulkUpdatePacket } from "../model/net/ub04-revenue-code-bulk-update-packet";
import { Ub04DiagnosisCodeBulkUpdatePacket } from "../model/net/ub04-diagnosis-code-bulk-update-packet";
import { UB04ConditionCodeBulkPacket } from "../model/net/ub04-condition-code-bulk-packet";
import { UB04OccurrenceCodeBulkPacket } from "../model/net/ub04-occurrence-code-bulk-packet";
import { UB04ValueCodeBulkPacket } from "../model/net/ub04-value-code-bulk-packet";
import { UB04ProcedureCodeBulkPacket } from "../model/net/ub04-procedure-code-bulk-packet";
import { UB04PayerBulkPacket } from "../model/net/ub04-payer-bulk-packet";
import { UB04ReportPacket } from "../model/net/ub04-report-packet";

@Injectable({
  providedIn: 'root'
})
export class Ub04Service {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  private ub04ReviewTypes$ = new BehaviorSubject(null);
  private poaValues$ = new BehaviorSubject(null);
  private diagnosisCodeTypes$ = new BehaviorSubject(null);

  getAllUB04ReviewTypes(): Observable<any> {
    return this.ub04ReviewTypes$.value != null
      ? this.ub04ReviewTypes$.asObservable()
      : this.httpClient
        .get<any>(this.environment.baseApiUrl + "ub04/review-types")
        .pipe(tap((res) => {
          if (!res.hasErrors && res.data) {
            this.ub04ReviewTypes$.next(res);
          }
        }));
  }

  getAllPOAValues(): Observable<any> {
    return this.poaValues$.value != null
      ? this.poaValues$.asObservable()
      : this.httpClient
        .get<any>(this.environment.baseApiUrl + "ub04/present-on-admission-values")
        .pipe(tap((res) => {
          if (!res.hasErrors && res.data) {
            this.poaValues$.next(res);
          }
        }));
  }

  getUB04DiagnosisCodeTypes(): Observable<any> {
    return this.diagnosisCodeTypes$.value != null
      ? this.diagnosisCodeTypes$.asObservable()
      : this.httpClient
        .get<any>(this.environment.baseApiUrl + "ub04/diagnosis-code-types")
        .pipe(tap((res) => {
          if (!res.hasErrors && res.data) {
            this.diagnosisCodeTypes$.next(res);
          }
        }));
  }

  getAllUB04forMICase(miCaseId: number, first: number = 0, max: number = 9999): Observable<any> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04/list/" + miCaseId.toString()
      + "?first=" + first + "&max=" + max);
  }

  getUb04ReviewById = (ub04Id: number): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04/review/" + ub04Id.toString());
  }

  getUb04Remarks(ub04Id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04/remarks/" + ub04Id.toString());
  }
  putUb04Remarks(payload: UB04RemarksUpdatePacket): Observable<any> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04/remarks", payload);
  }


  getUb04DiagnosisCodes(ub04Id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04-child/diagnosis-code/list/" + ub04Id.toString());
  }

  getUb04ListForMICase(miCaseId: number, first?: number, max?: number): Observable<any> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04/list/mi-case/" + miCaseId.toString() + "?first=" + first + "&max=" + max);
  }

  getUb04Info(ub04Id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04/info/" + ub04Id.toString());
  }

  getUb04RevenueCodes(ub04Id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04-child/revenue-code/list/" + ub04Id.toString());
  }

  getUb04Edit1(ub04Id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04/edit1/" + ub04Id.toString());
  }

  getUb04Providers(ub04Id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04-child/provider/list/" + ub04Id.toString());
  }

  getUb04EditPatient(ub04Id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ub04/edit-patient/" + ub04Id.toString());
  }

  addUB04(payload: UB04Create): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "ub04/create", payload);
  }

  putUb04Edit1(payload: UB04Edit1): Observable<any> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04/edit1", payload);
  }

  postUb04CreateProvider(payload: any): Observable<any> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "ub04-child/provider/create", payload);
  }

  postUb04ImportProvider(payload: any): Observable<any> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "ub04-child/provider/import", payload);
  }

  deleteUb04Provider(id: number): Observable<any> {
    return this.httpClient.delete<any>(this.environment.baseApiUrl + "ub04-child/provider/" + id.toString(10));
  }

  // TODO: Strong type the payload
  putUb04EditPatient(payload: any): Observable<any> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04/edit-patient", payload);
  }

  putUb04RevenueCodesBulkUpdate(payload: UB04RevenueCodeBulkUpdatePacket): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04-child/revenue-code/bulkupdate", payload);
  }

  putUb04DiagnosisCodesBulkUpdate(payload: Ub04DiagnosisCodeBulkUpdatePacket): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04-child/diagnosis-code/bulkupdate", payload);
  }

  getUb04ConditionCodes(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}ub04-child/condition-code/list/${id}`);
  }

  putUb04ConditionCodesBulkUpdate(payload: UB04ConditionCodeBulkPacket){
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04-child/condition-code/bulkupdate", payload);
  }

  getUb04OccurrenceCodes(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}ub04-child/occurrence-code/list/${id}`);
  }

  putUb04OccurrenceCodesBulkUpdate(payload: UB04OccurrenceCodeBulkPacket){
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04-child/occurrence-code/bulkupdate", payload);
  }

  getUb04ValueCodes(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}ub04-child/value-code/list/${id}`);
  }

  putUb04ValueCodesBulkUpdate(payload: UB04ValueCodeBulkPacket){
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04-child/value-code/bulkupdate", payload);
  }

  getUb04ProcedureCodes(id: number, orderBy: string = ""): Observable<BaseResponse> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}ub04-child/procedure-code/list/${id}?orderBy=${orderBy}`);
  }

  putUb04ProcedureCodesBulkUpdate(payload: UB04ProcedureCodeBulkPacket): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "ub04-child/procedure-code/bulkupdate", payload);
  }

  getUb04Payers(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}ub04-child/payer/list/${id}`)
  }

  putUb04PayersBulkUpdate(payload: UB04PayerBulkPacket): Observable<BaseResponse> {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}ub04-child/payer/bulkupdate`, payload);
  }

  getUb04PrimaryPayerByMICaseId(miCaseId: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}ub04-child/payer/primary/${miCaseId}`);
  }

  generateUb04Report(payload: UB04ReportPacket): Observable<BaseResponse> {
    return this.httpClient.post<any>(`${this.environment.baseApiUrl}report/ub04`, payload);
  }

  getAllUB04VisitTypes(): Observable<BaseResponse> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}ub04/visit-types`);
  }

  toggleUB04ActiveStatus(id:number): Observable<BaseResponse> {
    return this.httpClient.delete<any>(`${this.environment.baseApiUrl}ub04/${id}`);
  }

  removePatientFromMICaseByUB04Id = (id:number) => {
    return this.httpClient.delete<any>(`${this.environment.baseApiUrl}ub04/remove-patient/${id}`)
  }

  searchExistingPatients = (packet:any, first:number, max:number) => {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}ub04/patient-search?first=${first}&max=${max}`, packet);
  }

  setExistingPatientById = (ub04Id:number, patientId:number) => {
    return this.httpClient.post<any>(`${this.environment.baseApiUrl}ub04/set-patient/${ub04Id}/${patientId}`, {});
  }

  // The preferred pathway to IB Request Letter generation.
  genIBRequestLetter = (ub04Id: number, attachmentType: string): Observable<any> => {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}ub04/gen-ib-request-letter/${ub04Id}/${attachmentType}`, {});
  }

}
