import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { BaseResponse } from "../../model/base-response";
import { DateUtil } from "../../util/date-util";
import { CodeValidationService } from "../../code/code-validation.service";
import { StatusTrackService } from "../../status-track/status-track.service";
import { UB04ProcedureCodeBulkPacket } from "../../model/net/ub04-procedure-code-bulk-packet";
import { UB04ProcedureCodePacket } from "../../model/net/ub04-procedure-code-packet";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from "../../util/logger";



@Component({
  selector: 'app-ub04-edit-procedure-code-dialog',
  templateUrl: './ub04-edit-procedure-code-dialog.component.html',
  styleUrls: ['./ub04-edit-procedure-code-dialog.component.scss',
  '../ub04-dialog-base-styles.scss']
})
export class Ub04EditProcedureCodeDialogComponent extends UB04StatusTrackBaseDialogComponent implements OnInit, AfterViewInit {
  override log = new Logger("Ub04EditProcedureCodeDialogComponent");
  formGroup!: FormGroup;
  ub04ProcedureCodes: any [] = [];

  sortOptions = [
    "createdDate", "serviceDate"
  ];
  sort = this.sortOptions[0];
  canSort = true;

  constructor(
    private codeValidationService: CodeValidationService,
    public dialogRef: MatDialogRef<Ub04EditProcedureCodeDialogComponent>,
    private statusTrackService: StatusTrackService,
    override snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_PROCEDURE_CODE, statusTrackService);
    this.constructFormGroup();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.fetchData();
  }

  getRowDisplayStyle(idx: number): any {
    let pcFormGroup: FormGroup = this.getProcedureCodeFormGroup(idx);
    if (pcFormGroup.controls["deleteRow"].value) {
      return {
        "background-color": "rgba(255, 0, 0, 0.32)"
      };
    } else {
      return {};
    }
  }

  getIconStyle(idx: number){
    let pcFormGroup: FormGroup = this.getProcedureCodeFormGroup(idx);
    let color = 'green';

    if(!pcFormGroup.valid){
      color = 'orange'
    }

    if(pcFormGroup.controls["deleteRow"].value){
      color = 'red'
    }

    return {
      color
    }
  }

  getDeleteRowByIdx(idx: number): any {
    const pcFormGroup: FormGroup = this.getProcedureCodeFormGroup(idx);
    const val = pcFormGroup.controls["deleteRow"].value;
    return !!val && val !== "";
  }

  getDeleteRowOrBlankValueByIdx(idx: number): any {
    let pcFormGroup: FormGroup = this.getProcedureCodeFormGroup(idx);
    if (pcFormGroup.controls["deleteRow"].value) {
      return true;
    }

    return !pcFormGroup.controls["procedureCode"].value;
  }

  constructFormGroup() {
    this.formGroup = new FormGroup({
      procedureCodes: new FormArray([])
    });
  }

  get procedureCodes(): FormArray {
    return this.formGroup.controls["procedureCodes"] as FormArray;
  }

  getProcedureCodeFormGroup(idx: number): FormGroup {
    return this.procedureCodes.at(idx) as FormGroup;
  }

  populateProcedureCodesOnFormGroup() {
    if (this.ub04ProcedureCodes.length <= 0) {
      this.onAddProcedureCodeLine();
      return;
    }

    let procedureCodes = this.procedureCodes;
    procedureCodes.clear();
    let idx = 0;
    for (idx = 0; idx < this.ub04ProcedureCodes.length; idx++) {
      let pc = this.ub04ProcedureCodes[idx];
      let pcFormGroup: FormGroup = new FormGroup({
        id: new FormControl(pc.id),
        procedureCode: new FormControl(pc.procedureCode, [Validators.required, Validators.maxLength(8)]),
        description: new FormControl(pc.procedureCodeDescription),
        serviceDate: new FormControl(DateUtil.fennecDBStringToInputDateString(pc.serviceDate), [Validators.required]),
        tempKey: new FormControl(this.codeValidationService.createUUID()),
        deleteRow: new FormControl(false),
      });
      pcFormGroup.controls['description'].disable();

      pcFormGroup.controls['procedureCode'].valueChanges.subscribe((val) => {
        this.validateProcedureCode(pcFormGroup, val);
      });

      if(pc.ub04ProcedureCodeType === "PRINCIPAL"){
        procedureCodes.insert(0, pcFormGroup);
      }else{
        procedureCodes.push(pcFormGroup);
      }
    }
  }

  onAddProcedureCodeLine() {
    this.canSort = false;
    let procedureCodes = this.procedureCodes;
    let pcFormGroup: FormGroup = new FormGroup({
      id: new FormControl(-1),
      procedureCode: new FormControl("", [Validators.required, Validators.maxLength(8)]),
      description: new FormControl(),
      serviceDate: new FormControl("", [Validators.required, Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$')]),
      tempKey: new FormControl(this.codeValidationService.createUUID()),
      deleteRow: new FormControl(false),
    }, {
      // updateOn: 'blur'
    });
    pcFormGroup.controls['description'].disable();
    // Chatty procedure code validation during data entry
    pcFormGroup.controls['procedureCode'].valueChanges.subscribe((val) => {
      this.validateProcedureCode(pcFormGroup, val);
    });
    procedureCodes.push(pcFormGroup);
  }

  validateProcedureCode(row: FormGroup, procedureCodeRow: any) {
    let pcFormControl: FormControl = row.controls['procedureCode'] as FormControl;
    let descriptionFormControl: FormControl = row.controls['description'] as FormControl;

    pcFormControl.markAllAsTouched();
    if (pcFormControl.invalid) {
      pcFormControl.setErrors({'incorrect': true});
      descriptionFormControl.setValue("Invalid");
      return;
    }
    if (pcFormControl.value.length >= 1) {
      this.codeValidationService.getProcedureCode(pcFormControl.value).subscribe((response: BaseResponse) => {
        if (response.hasErrors) {
          pcFormControl.markAsPristine();
          pcFormControl.setErrors({'incorrect': true});
        } else {
          if (response.data !== null) {
            pcFormControl.markAsPristine();
            descriptionFormControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
            pcFormControl.setErrors(null);
          } else {
            descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
            pcFormControl.markAsPristine();
            pcFormControl.setErrors({'incorrect': true});
  
          }
        }
      });
    }
  }

  findControlUsingTempKey(tempKey: string): FormGroup {
    let ctrl: FormGroup = new FormGroup({});
    let idx = 0;
    for (idx = 0; idx < this.procedureCodes.length; idx++) {
      let rc: FormGroup = this.getProcedureCodeFormGroup(idx);
      if (rc.controls['tempKey'].value === tempKey) {
        ctrl = rc;
        break;
      }
    }
    return ctrl;
  }

  fetchData() {
    this.ub04Service.getUb04ProcedureCodes(this.data.ub04Id, this.sort).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04ProcedureCodes = response.data;
        this.populateProcedureCodesOnFormGroup();
      }
    });
  }

  onDelete(idx: number) {
    let rfg: FormGroup = this.getProcedureCodeFormGroup(idx);
    if (rfg.controls["id"].value < 0) {
      this.procedureCodes.removeAt(idx);
    } else {
      rfg.controls["deleteRow"].setValue(!rfg.controls["deleteRow"].value);
    }
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    let updDto: UB04ProcedureCodeBulkPacket = new UB04ProcedureCodeBulkPacket();
    updDto.ub04Id = parseInt(this.data.ub04Id);
    let pcRows: FormArray = this.procedureCodes;
    pcRows.controls.forEach((pcRow: any, index: number) => {
      let revCode = pcRow.controls["procedureCode"].value;
      if (revCode !== null && revCode !== undefined) {
        let pcDto: UB04ProcedureCodePacket = new UB04ProcedureCodePacket();
        pcDto.id = pcRow.controls["id"].value;
        pcDto.ub04Id = parseInt(this.data.ub04Id);
        pcDto.procedureCode = pcRow.controls["procedureCode"].value;
        pcDto.serviceDate = DateUtil.inputDateStringToFennecDBDateString(pcRow.controls["serviceDate"].value) ?? undefined;
        pcDto.deleteRow = pcRow.controls["deleteRow"].value;
        pcDto.ub04ProcedureCodeType = index === 0 ? "PRINCIPAL" : "OTHER";
        updDto.ub04ProcedureCodes.push(pcDto);
      }
    });


    this.ub04Service.putUb04ProcedureCodesBulkUpdate(updDto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });
  }
}
