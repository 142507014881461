import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { animate, style, transition, trigger } from '@angular/animations';
import { ModifyAttachmentDialogComponent } from './modify-attachment-dialog/modify-attachment-dialog.component';
import { ActivatedRoute } from "@angular/router";
import {
  AttachmentPacket,
  AttachmentService,
  BaseComponent,
  FennecSnackbarService,
  FilePacket,
  FileType,
  FileUtil,
  ItemizedCreateComponent,
  Logger,
  SingleChoiceDialogComponent
} from "xf-common";
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0}))
      ])
    ])
  ]
})
export class AttachmentComponent extends BaseComponent implements OnInit {

  protected log = new Logger("AttachmentComponent");

  // The context in which this component is operating. Logic can then pivot based upon what
  // kind of attachment you are trying to upload/create.
  // MICASE - MiCase Attachment
  // EDIRUN - Uploads/Creates an EDI Run from an EDI 837 file.
  // EDIATTACHMENTS - Views/Uploads EDI Associated Attachments
  @Input()
  context: string = "MICASE";

  // If the attachment is to be associated with a MICase, provide the id here.
  @Input()
  miCaseId: string = "-1";

  // If the component is to show a list of already existing attachments. Set this to "true". You
  // are responsible for retrieving the list of attachments you want to show, given the
  // circumstances.
  // Ex: when creating an EDIRun, we don't want to show any existing attachments.
  @Input()
  showAttachmentList: string = "true";

  // If set to "true" the upload prompt will be shown on component.  Defaults to true.
  // Set to "false" to hide the upload portion of the component.
  @Input()
  showUpload: string = "true";

  // Optional. A header string to display on the component. Useful for indicating the type of
  // attachment operation you are currently in.
  @Input()
  headerString: string | null = null;

  // If the attachment context is EDIATTACHMENTS, provide the EDI Run Id
  @Input()
  ediRunId: string = "-1"

  saveAttachment: Subject<any> = new Subject();
  attachmentSaveComplete: Subject<any> = new Subject();

  // These two fields will only be populated on edits
  id?: number;
  externalObjectId?: string;

  name: string = "";
  file?: File;
  attachmentList: any [] = [];
  attachmentTypes: string[] = [];
  attachmentType: string = "Itemized";
  attachmentPublishToWeb: boolean = true;

  selectedAttachmentId: string = "-1";
  selectedAttachment: any = null;

  // attachmentViewAmount: number = 5;
  // attachmentViewList: any [] = [];
  // currentIndex:number = 0;

  uploading: boolean = false;

  @ViewChild('paginator')
  paginator?: MatPaginator;

  defaultPageSize:number = 5;
  totalRowCount:number = 0;

  constructor(
    protected snack: FennecSnackbarService,
    protected attachmentService: AttachmentService,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) {
    super();

    this.saveAttachment.subscribe((data) => {
      this.saveAttachmentToServer(this.file);
    });
   }

  ngOnInit(): void {
    if (this.miCaseId === "-1") { // only overwrite if it's still the default
      this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    }
    if (this.miCaseId != null) {
      this.context = "MICASE";
    }

    this.performXFRequest({
      requestDescription: "Get Attachment Types",
      requestFn: this.attachmentService.getAttachmentTypes,
      fnParams: [],
      onSuccess: (data: string[]) => {
        if (this.context === "EDIRUN") {
          this.attachmentTypes = ["EDI_837"];
          this.attachmentType = "EDI_837";
        } else {
          this.attachmentTypes = data;
        }
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
      });

    this.getAttachmentList();
  }

  getAttachmentList() {
    if (this.showAttachmentList !== "true") {
      return;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    if(this.context === "MICASE") {
      this.attachmentService.getAllAttachmentsforMICase(parseInt(this.miCaseId, 10), first, pageSize).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.attachmentList = response.data;
          this.totalRowCount = response.totalRowCount;
          if (this.attachmentList.length > 0) {
            this.selectAttachment(this.attachmentList[0].id);
            // this.getAttachmentViewList(0);
          }else{
            // this.attachmentViewList = [];
            this.deselectAttachment();
          }
        }
      });
    }

    if (this.context === "EDIATTACHMENTS") {
      this.attachmentService.getAllAttachmentsForEDIRun(parseInt(this.ediRunId, 10), first, pageSize).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.attachmentList = response.data;
          if (this.attachmentList.length > 0) {
            this.selectAttachment(this.attachmentList[0].id);
            // this.getAttachmentViewList(0);
          } else{
            // this.attachmentViewList = [];
            this.deselectAttachment();
          }
        }
      });
    }
  }

  selectAttachment(id: number): void {
    this.selectedAttachmentId = id.toString();
    this.selectedAttachment = this.getAttachment(id);
  }

  getAttachment(id: number): any | null {
    return this.attachmentList.find(e => {
      return e.id === id;
    })
  }

  getSelectedStyle(id: number):any {
    if (id.toString() === this.selectedAttachmentId) {
      return {
        "background-color": "powderblue"
      }
    } else {
      return {
        "background-color": "#E5E6EB"
      }
    }
  }

  addFile(input: any) {
    this.file = input.files[0];
    if (this.name === null || this.name === undefined || this.name.length === 0) {
      this.name = this.file?.name !== null && this.file?.name !== undefined ? this.file.name : "";
    }
  }

  saveAttachmentToServer(file?: File) {
    if (file == null) {
      super.showErrorSnack("Cannot save NULL file: " + this.id);
      return;
    }

    this.uploading = true;

    FileUtil.convertFileToFilePacket(file)
      .then((filePacket: FilePacket) => {
        const dto = new AttachmentPacket();
        dto.id = this.id;
        dto.name = this.name;
        dto.miCaseId = parseInt(this.miCaseId);
        dto.externalObjectId = this.externalObjectId;
        dto.attachmentType = this.attachmentType;
        dto.filePacket = filePacket;
        dto.publishToWeb = this.attachmentPublishToWeb;

        this.isLoading = true;

        this.performXFRequest({
          requestDescription: "Update Attachment",
          requestFn:
            // Note: this code was removed due to circular dependency errors. Please revisit if needed.
            // this.context === "MICASE" ?
            //  this.attachmentService.updateAttachment :
            //  this.ediRunService.createUpdateEDIRun,
            this.attachmentService.updateAttachment,
          fnParams: [dto],
          onSuccess: (data: boolean) => {
            if (this.context === "MICASE") {
              if (dto.attachmentType == "Itemized_DE_SS" || dto.attachmentType == "Itemized_SS") {
                super.showWorkerSubmitSnack("Itemized SS has been uploaded and submitted for processing!");
              } else {
                super.showSuccessSnack("Successfully updated Attachment!");
              }
            } else {
              super.showSuccessSnack("EDI Run Created!");
            }
            this.attachmentSaveComplete.next(data);
            this.uploading = false;
          },
          onComplete: () => {
            this.isLoading = false;
            this.name = "";
            this.attachmentType = "Itemized";
            this.file = undefined;
            this.attachmentPublishToWeb = true;
            this.getAttachmentList();
            this.uploading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
            this.isLoading = false;
            this.name = "";
            this.attachmentType = "Itemized";
            this.file = undefined;
            this.attachmentPublishToWeb = true;
            this.getAttachmentList();
            this.uploading = false;
          }
        });
      })
      .catch((error: any) => {
        const errorMessage = super.processError("Error updating attachment: File size limit exceeded for file: " + this.name, error);
        console.log(error);
        super.showErrorSnack(errorMessage);
        this.uploading = false;
      });
  }

  onSave() {
    this.saveAttachmentToServer(this.file);
  }

  disableSave(): boolean {
    if(
      this.uploading || (
        this.name.length > 0 &&
        this.file !== undefined &&
        this.attachmentType !== undefined
      )){
        return false;
      }

    return true;
  }

  downloadAttachment(attachmentId: number) {
    this.performXFRequest({
      requestDescription: "Download Attachment",
      requestFn: this.attachmentService.downloadAttachment,
      fnParams: [attachmentId],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("downloading attachment");
        FileUtil.downloadFileInBrowser(response, document.createElement("a"), FileType.PDF, response.fileName);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  emailAttachment(attachmentId: number) {
    this.performXFRequest({
      requestDescription: "Email Attachment",
      requestFn: this.attachmentService.emailAttachment,
      fnParams: [attachmentId],
      onSuccess: data => {
        super.showSuccessSnack(data);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  deleteAttachmentConfirmation(attachment: AttachmentPacket): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Delete Attachment",
      infoLine1 : `Delete ${attachment.name}?`
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.deleteAttachment(attachment);
      }
    });
  }

  deleteAttachment(packet: AttachmentPacket): void {
    if(packet.id == undefined) {
      super.showErrorSnack("No ID provided in packet");
      return;
    }

    this.performXFRequest({
      requestDescription: "Delete Attachment",
      requestFn: this.attachmentService.deleteAttachment,
      fnParams: [packet.id],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("Attachment deleted");
        // this.removeAttachmentFromList(packet.id);
        this.getAttachmentList();
        // FileUtil.downloadFileInBrowser(response, document.createElement("a"), FileType.PDF, response.fileName);
      },
      onComplete: () => {
        this.isLoading = false;
        this.getAttachmentList();
      },
      onError: (error) => {
        super.showErrorSnack(error);
        this.getAttachmentList();
      }
    });
  }

  removeAttachmentFromList(id?:number) {
    for(let i = 0; i < this.attachmentList.length; i++) {
      const attachment = this.attachmentList[i];
      if(attachment.id === id) {
        this.attachmentList.splice(i);
        return;
      }
    }
  }

  convertToItemized(id: number): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCaseId : this.miCaseId,
      attachmentId: id,
      origin: 'UPLOAD'
    };
    const dialogRef = this.matDialog.open(ItemizedCreateComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        // TODO: Refresh list maybe?
      }
    });
  }

  // getAttachmentViewList(index:number) {
  //   const result = [];
  //   for(let i = 0; i < this.attachmentViewAmount; i++) {
  //     const attachment = this.getAttachmentAtIndex(index + i);
  //     if(attachment != null) {
  //       result.push(attachment);
  //     }
  //   }

  //   this.currentIndex = index;
  //   this.attachmentViewList = result;
  // }

  getAttachmentAtIndex(index:number) {
    if(index < this.attachmentList.length) {
      return this.attachmentList[index]
    }

    return null;
  }

  getAttachmentById(idString:string) {
    const id = parseInt(idString, 10);
    let result = null;
    this.attachmentList.forEach((attachment) => {
      if(attachment.id === id) {
        result = attachment;
        return;
      }
    })

    return result;
  }

  deselectAttachment() {
    this.selectedAttachment = null;
    this.selectedAttachmentId = "-1";
  }

  viewAttachment(attachment:any): void {
    window.open(`/view-attachment/${attachment.id}`, "_blank");
  }

  isFilePdf(attachment:any):boolean {
    let result = false;

    if(attachment.name && attachment.name.endsWith(".pdf")) {
      result = true;
    }

    return result;
  }

  modifyAttachmentDialog = (attachment:any) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      attachment:attachment,
      attachmentTypes: this.attachmentTypes
    };
    const dialogRef = this.matDialog.open(ModifyAttachmentDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getAttachmentList();
    });
  }

  togglePublishToWeb = (attachment:any) => {
    this.performXFRequest({
      requestDescription: "Toggle Attachment Publish To Web",
      requestFn: this.attachmentService.toggleAttachmentPublishToWeb,
      fnParams: [attachment.id],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack(`'${attachment.name}' succuessfuly ${attachment.publishToWeb ? "hidden from" : "published to"} client portal`);
        // this.removeAttachmentFromList(packet.id);
        this.getAttachmentList();
        // FileUtil.downloadFileInBrowser(response, document.createElement("a"), FileType.PDF, response.fileName);
      },
      onComplete: () => {
        this.isLoading = false;
        this.getAttachmentList();
      },
      onError: (error) => {
        super.showErrorSnack(error);
        this.getAttachmentList();
      }
    });
  }

  confirmPublishDialog = (attachment:any) => {
    let publishUnpublish = attachment.publishToWeb ? "hide" : "publish";
    let toFrom = attachment.publishToWeb ? "from" : "to";
    let currentStatus = attachment.publishToWeb ? "public" : "hidden";

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question: `Are you sure you want to ${publishUnpublish} '${attachment.name}' ${toFrom} client portal?`,
      infoLine1: `Attachment is currently ${currentStatus}`
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result.confirm) {
        this.togglePublishToWeb(attachment);
      }
    });
  }
}
