import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, Subject, throwError } from "rxjs";
import { CaseCreate } from "../model/net/mi-case-create";
import { BaseResponse } from "../model/base-response";
import { CaseEdit } from "../model/net/mi-case-edit";
import { MICaseFinanceUpdatePacket } from "../model/net/mi-case-finance-update-packet";
import { SimpleObject } from "../model/simple-object";
import { DataResponse } from "../model/data-response";
import { EnvironmentService } from "../environment.service";
import { PagedResponse } from "../model/paged-response";

@Injectable({
  providedIn: 'root'
})
export class MICaseService {

  private refreshCaseInfo = new Subject<void>();
  refreshCaseInfo$ = this.refreshCaseInfo.asObservable();
  emitRefreshCaseInfo() {
    this.refreshCaseInfo.next();
  }
  private refreshChildComponents = new Subject<void>();
  refreshChildComponents$ = this.refreshChildComponents.asObservable();
  emitRefreshChildComponents() {
    this.refreshChildComponents.next();
  }

  private miCaseUrl = `${this.environment.baseApiUrl}mi-case/`;

  constructor(
      private httpClient: HttpClient,
      private environment: EnvironmentService
  ) {}

  getAllCases = (queryParams: {}, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.put<PagedResponse<any>>(this.environment.baseApiUrl + "mi-case/mi-case-list?first=" + first + "&max=" + max, queryParams);
  }

  addCase(payload: CaseCreate): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "mi-case/create", payload);
  }

  updateCase(data: any): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.miCaseUrl + "update", data);
  }

  updateCaseDialog = (payload: CaseEdit): Observable<DataResponse<any>> => {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}mi-case/update-dialog`, payload);
  }

  deleteCase(id: number): Observable<BaseResponse> {
    return this.httpClient.delete<any>(this.miCaseUrl + "destroy/" + id);
  }

  // TODO: This is currently not functional on UI - determine if we need this or not.
  submitMICase = (miCaseId: number): Observable<any> => {
    return this.httpClient.post<any>(this.environment.baseApiUrl + `mi-case/submit/${miCaseId}`, {});
  }

  // TODO: This is currently not functional on UI - determine if we need this or not.
  openTaskCheck = (miCaseId: number): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + `mi-case/opentaskcheck/${miCaseId}`);
  }

  completeMICase = (id:number): Observable<any> => {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}mi-case/status-track/complete`, id);
  }

  getMICaseMainStatusTrackStatuses = (): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(`${this.miCaseUrl}status-track-statuses`);
  }

  getMICaseInfo = (id:number): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}mi-case/info/${id}`);
  }

  getMICaseFinanceInfo = (id:number): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}mi-case/finance/${id}`);
  }

  updateMICaseFinance = (payload: MICaseFinanceUpdatePacket): Observable<DataResponse<any>> => {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}mi-case/finance`, payload);
  }

  toggleMICaseHold = (data?: SimpleObject): Observable<DataResponse<any>> => {
    return this.httpClient.put<any>(`${this.miCaseUrl}status-track/toggle-hold`, data);
  }

  getCaseTypes = (): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "mi-case/case-types");
  }

  getCustomerPriorities = (): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "mi-case/customer-priorities");
  }

  getHoldReasons = (): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "mi-case/hold-reasons");
  }

  getLinesOfBusiness = (): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "mi-case/lines-of-business");
  }

  getProviderParValues = (): Observable<any> => {
    return this.httpClient.get<any>(`${this.miCaseUrl}provider-par`);
  }

  updateMICasePrimaryClient = (payload: any): Observable<any> => {
    return this.httpClient.put<any>(`${this.miCaseUrl}update-primary-client`, payload);
  }

  updateMICaseHealthCarePlan = (payload:any): Observable<any> => {
    return this.httpClient.put<any>(`${this.miCaseUrl}update-health-plan`, payload);
  }

  // Note: not the preferred method of IB Request Letter generation. See UB04Service.
  genIBRequestLetter = (miCaseId: number, attachmentType: string): Observable<any> => {
    return this.httpClient.put<any>(`${this.miCaseUrl}gen-ib-request-letter/${miCaseId}/${attachmentType}`, {});
  }

}
