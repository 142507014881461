import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserProfileService } from "./user/user-profile.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory'
import { SystemAdminComponent } from "./admin/system-admin/system-admin.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { UserUpdateFormComponent } from "./user-profile/user-update-form/user-update-form.component";
import { ProviderListComponent } from './provider/provider-list/provider-list.component';
import { CommonModule } from "@angular/common";
import {
  ApiInterceptor,
  CommonViewModule,
  CompanyService,
  EnvironmentService,
  FennecSnackbarService,
  ItemizedService,
  MaterialModule,
  ProviderService,
  StateService,
  XfCommonModule
} from 'xf-common';
import { StateAdminListComponent } from './admin/state-admin/state-list/state-admin-list.component';
import { MICaseModule } from "@app/mi-case/mi-case.module";
import { AttachmentModule } from './attachment/attachment.module';
import { ConditionCodeComponent } from './admin/condition-code-admin/condition-code.component';
import { ConditionCodeService } from './admin/condition-code-admin/condition-code.service';
import {
  ConditionCodeCreateFormComponent
} from './admin/condition-code-admin/condition-code-create-form/condition-code-create-form.component';
import {
  ConditionCodeEditFormComponent
} from './admin/condition-code-admin/condition-code-edit-form/condition-code-edit-form.component';
import {
  ConditionCodeDeleteFormComponent
} from './admin/condition-code-admin/condition-code-delete-form/condition-code-delete-form.component';
import {
  PatientRelationshipCodeListComponent
} from './admin/patient-relationship-code-admin/patient-relationship-code-list/patient-relationship-code-list.component';
import {
  PatientRelationshipCodeDialogComponent
} from './admin/patient-relationship-code-admin/patient-relationship-code-dialog/patient-relationship-code-dialog.component';
import {
  PatientRelationshipCodeService
} from './admin/patient-relationship-code-admin/patient-relationship-code.service';
import {
  OccurrenceCodeDialogComponent
} from './admin/occurrence-code-admin/occurrence-code-dialog/occurrence-code-dialog.component';
import {
  OccurrenceCodeListComponent
} from './admin/occurrence-code-admin/occurrence-code-list/occurrence-code-list.component';
import { OccurrenceCodeService } from './admin/occurrence-code-admin/occurrence-code.service';
import {
  RevenueCodeDialogComponent
} from './admin/revenue-code-admin/revenue-code-dialog/revenue-code-dialog.component';
import { RevenueCodeListComponent } from './admin/revenue-code-admin/revenue-code-list/revenue-code-list.component';
import { RevenueCodeService } from './admin/revenue-code-admin/revenue-code.service';
import {
  DischargeStatusCodeListComponent
} from './admin/discharge-status-code-admin/discharge-status-code-list/discharge-status-code-list.component';
import {
  DischargeStatusCodeDialogComponent
} from './admin/discharge-status-code-admin/discharge-status-code-dialog/discharge-status-code-dialog.component';
import { DischargeStatusCodeService } from "@app/admin/discharge-status-code-admin/discharge-status-code.service";
import {
  DiagnosisCodeListComponent
} from './admin/diagnosis-code-admin/diagnosis-code-list/diagnosis-code-list.component';
import {
  DiagnosisCodeDialogComponent
} from './admin/diagnosis-code-admin/diagnosis-code-dialog/diagnosis-code-dialog.component';
import { DiagnosisCodeService } from "@app/admin/diagnosis-code-admin/diagnosis-code-service";
import { ValueCodeService } from './admin/value-code-admin/value-code.service';
import { ValueCodeListComponent } from './admin/value-code-admin/value-code-list/value-code-list.component';
import { ValueCodeDialogComponent } from './admin/value-code-admin/value-code-dialog/value-code-dialog.component';
import { StateDialogComponent } from './admin/state-admin/state-dialog/state-dialog.component';
import {
  ProcedureCodeListComponent
} from './admin/procedure-code-admin/procedure-code-list/procedure-code-list.component';
import {
  ProcedureCodeDialogComponent
} from './admin/procedure-code-admin/procedure-code-dialog/procedure-code-dialog.component';
import { ProcedureCodeService } from './admin/procedure-code-admin/procedure-code.service';
import { HcpcsCodeListComponent } from './admin/hcpcs-code-admin/hcpcs-code-list/hcpcs-code-list.component';
import { HcpcsCodeDialogComponent } from './admin/hcpcs-code-admin/hcpcs-code-dialog/hcpcs-code-dialog.component';
import { HCPCSCodeService } from './admin/hcpcs-code-admin/hcpcs-code.service';
import { TaxonomyCodeListComponent } from './admin/taxonomy-code-admin/taxonomy-code-list/taxonomy-code-list.component';
import {
  TaxonomyCodeDialogComponent
} from './admin/taxonomy-code-admin/taxonomy-code-dialog/taxonomy-code-dialog.component';
import { TaxonomyCodeService } from './admin/taxonomy-code-admin/taxonomy-code.service';
import {
  ItemizedLineItemAdjDialogComponent
} from './itemized/itemized-line-item/itemized-line-item-adj-dialog/itemized-line-item-adj-dialog.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { ProviderComponent } from './provider/provider/provider.component';
import { ProviderEditComponent } from './provider/provider-edit/provider-edit.component';
import { ProviderAddComponent } from "@app/provider/provider-add/provider-add.component";
import { CompanyListComponent } from './company/company-list/company-list.component';
import { CompanyEditComponent } from './company/company-edit/company-edit.component';
import { CompanyComponent } from "@app/company/company.component";
import { CompanyAddComponent } from './company/company-add/company-add.component';
import { ActivityLogListComponent } from './activity-log/activity-log-list/activity-log-list.component';
import {
  AdjustmentReasonCodeDialogComponent
} from './admin/adjustment-reason-code/adjustment-reason-code-dialog/adjustment-reason-code-dialog.component';
import {
  AdjustmentReasonCodeListComponent
} from './admin/adjustment-reason-code/adjustment-reason-code-list/adjustment-reason-code-list.component';
import {
  AdjustmentExplanationCodeListComponent
} from './admin/adjustment-explanation-code/adjustment-explanation-code-list/adjustment-explanation-code-list.component';
import {
  AdjustmentExplanationCodeDialogComponent
} from './admin/adjustment-explanation-code/adjustment-explanation-code-dialog/adjustment-explanation-code-dialog.component';
import { AdjustmentReasonCodeService } from './admin/adjustment-reason-code/adjustment-reason-code.service';
import {
  AdjustmentExplanationCodeService
} from './admin/adjustment-explanation-code/adjustment-explanation-code.service';
import { ActivityLogService } from './activity-log/activity-log.service';
import { EdiRunComponent } from './edi-run/edi-run.component';
import { EdiRunCreateComponent } from './edi-run/edi-run-create/edi-run-create.component';
import { XFWFModule } from "@app/xfwf/xfwf.module";
import { ApeComponent } from './ape/ape-component/ape.component';
import { ApeCollectionListComponent } from './ape/ape-collection-list/ape-collection-list.component';
import { ApeCollectionDialogComponent } from './ape/ape-collection-dialog/ape-collection-dialog.component';
import { ApeRuleCollectionService } from './ape/ape-rule-collection.service';
import { ActivityDiaryDialogComponent } from './activity-diary/activity-diary-dialog/activity-diary-dialog.component';
import { EdiMicaseDialogComponent } from './edi-run/edi-micase-dialog/edi-micase-dialog.component';
import {
  EdiRunAttachmentDialogComponent
} from './edi-run/edi-run-attachment-dialog/edi-run-attachment-dialog.component';
import {
  EdiActivityDiaryDialogComponent
} from './edi-run/edi-activity-diary-dialog/edi-activity-diary-dialog.component';
import { ApeRuleListComponent } from './ape/ape-rule/ape-rule-list/ape-rule-list.component';
import { ApeRuleService } from './ape/ape-rule/ape-rule-list/ape-rule.service';
import { ApeRuleDialogComponent } from './ape/ape-rule/ape-rule-dialog/ape-rule-dialog.component';
import {
  ApeRuleCollectionRuleListComponent
} from './ape/ape-rule/ape-rule-collection-rule/ape-rule-collection-rule-list/ape-rule-collection-rule-list.component';
import {
  ApeRuleEventDefinitionListComponent
} from './ape/ape-rule/ape-rule-event-definition/ape-rule-event-definition-list/ape-rule-event-definition-list.component';
import {
  ApeRuleEventDefinitionListDialogComponent
} from './ape/ape-rule/ape-rule-event-definition/ape-rule-event-definition-list-dialog/ape-rule-event-definition-list-dialog.component';
import {
  ApeRuleEventDefinitionService
} from './ape/ape-rule/ape-rule-event-definition/ape-rule-event-definition.service';
import {
  ApeRuleEventDefinitionCreateEditComponent
} from './ape/ape-rule/ape-rule-event-definition/ape-rule-event-definition-create-edit/ape-rule-event-definition-create-edit.component';
import {
  ActivityDiaryAttachmentDialogComponent
} from './activity-diary/activity-diary-attachment-dialog/activity-diary-attachment-dialog.component';
import {
  ApeRuleFilterListComponent
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-list/ape-rule-filter-list.component';
import { ApeRuleFilterService } from './ape/ape-rule/ape-rule-filter/ape-rule-filter.service';
import {
  ApeRuleFilterFormDialogComponent
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-form-dialog/ape-rule-filter-form-dialog.component';
import {
  ApeRuleApeRuleFilterListComponent
} from './ape/ape-rule/ape-rule-filter/ape-rule-ape-rule-filter/ape-rule-ape-rule-filter-list/ape-rule-ape-rule-filter-list.component';
import {
  ApeRuleApeRuleFilterService
} from './ape/ape-rule/ape-rule-filter/ape-rule-ape-rule-filter/ape-rule-ape-rule-filter.service';
import {
  ApeRuleFilterStateDialogComponent
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-state/ape-rule-filter-state-dialog/ape-rule-filter-state-dialog.component';
import { ApeRuleFilterStateService } from './ape/ape-rule/ape-rule-filter/ape-rule-filter-state.service';
import {
  ItemizedRevisionCloneDialogComponent
} from './itemized/itemized-revision/itemized-revision-clone-dialog/itemized-revision-clone-dialog.component';
import { ItemizedEditDialogComponent } from './itemized/itemized-edit-dialog/itemized-edit-dialog.component';
import {
  MAT_LEGACY_DIALOG_DATA,
  MatLegacyDialogModule,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import {
  ItemizedApeRuleEventDiaryComponent
} from './itemized/itemized-line-item/itemized-ape-rule-event-diary/itemized-ape-rule-event-diary/itemized-ape-rule-event-diary.component';
import { PdfViewerComponent } from './attachment/pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {
  ActivityLogListSubDialogComponent
} from './activity-log/activity-log-list/activity-log-list-sub-dialog/activity-log-list-sub-dialog.component';
import {
  ActivityLogDialogComponent
} from './activity-log/activity-log-dialog/activity-log-dialog.component';
import { SystemConfigurationComponent } from './system-configuration/system-configuration.component';
import { TenantCompanyListComponent } from './system-configuration/tenant-company-list/tenant-company-list.component';
import { UserManagementComponent } from './user/user-management/user-management.component';
import {
  AdminUserProfileEditComponent
} from './user/user-management/admin-user-profile-edit/admin-user-profile-edit.component';
import {
  CompanyRelationalTypeAddComponent
} from './company/company-relational-type-add/company-relational-type-add.component';
import {
  ItemizedLineItemEditDialogComponent
} from './itemized/itemized-line-item/itemized-line-item-edit-dialog/itemized-line-item-edit-dialog.component';
import {
  DuplicateEntityDialogComponent
} from './duplicate-entity/duplicate-entity-dialog/duplicate-entity-dialog.component';
import { DuplicateEntityService } from "@app/duplicate-entity/duplicate-entity.service";
import { DRGCodeListComponent } from './admin/drg-code-admin/drg-code-list/drg-code-list.component';
import { DRGCodeDialogComponent } from './admin/drg-code-admin/drg-code-dialog/drg-code-dialog.component';
import { DRGCodeService } from './admin/drg-code-admin/drg-code.service';
import { JslModule } from './jsl/jsl.module';
import {
  HealthCarePlanDialogComponent
} from './health-care-plan/health-care-plan-dialog/health-care-plan-dialog.component';
import { HealthCarePlanListComponent } from './health-care-plan/health-care-plan-list/health-care-plan-list.component';
import {
  HealthCarePlanRelationalListComponent
} from './health-care-plan/health-care-plan-relational-list/health-care-plan-relational-list.component';
import {
  HealthCarePlanRelationalDialogComponent
} from './health-care-plan/health-care-plan-relational-dialog/health-care-plan-relational-dialog.component';
import {
  MiCasePlanEditDialogComponent
} from './health-care-plan/mi-case-plan-edit-dialog/mi-case-plan-edit-dialog.component';
import { HolidayEditDialogComponent } from './admin/holiday-admin/holiday-edit-dialog/holiday-edit-dialog.component';
import { HolidayListComponent } from './admin/holiday-admin/holiday-list/holiday-list.component';
import { HolidayService } from "@app/admin/holiday-admin/holiday.service";
import {
  HealthCarePlanUpdateAssignedCompanyDialogComponent
} from './health-care-plan/health-care-plan-update-assigned-company-dialog/health-care-plan-update-assigned-company-dialog.component';
import {
  ItemizedRevisionEditDialogComponent
} from './itemized/itemized-revision/itemized-revision-edit-dialog/itemized-revision-edit-dialog.component';
import {
  ApeRuleFilterPrimaryClientDialogComponent
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-primary-client-dialog/ape-rule-filter-primary-client-dialog.component';
import {
  ApeRuleFilterPrimaryClientService
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-primary-client.service';
import {
  ApeRuleFilterAddPrimaryClientDialogComponent
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-add-primary-client-dialog/ape-rule-filter-add-primary-client-dialog.component';
import {
  AddApeRuleFilterHealthCarePlanDialogComponent
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-health-care-plan/add-ape-rule-filter-health-care-plan-dialog/add-ape-rule-filter-health-care-plan-dialog.component';
import {
  ApeRuleFilterHealthCarePlanDialogComponent
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-health-care-plan/ape-rule-filter-health-care-plan-list/ape-rule-filter-health-care-plan-list.component';
import {
  ApeRuleFilterHealthCarePlanService
} from './ape/ape-rule/ape-rule-filter/ape-rule-filter-health-care-plan/ape-rule-filter-health-care-plan-service.service';
import { environmentValue } from "../environments/environment";
import {
  StatusTrackManualAssignComponent
} from "./status-track/status-track-manual-assign/status-track-manual-assign.component";
import { ItemizedLineItemAppealAdjDialogComponent } from './itemized/itemized-line-item/itemized-line-item-appeal-adj-dialog/itemized-line-item-appeal-adj-dialog.component';
import { UserGroupListComponent } from "./admin/user-group-admin/user-group-list/user-group-list.component";
import { UserGroupDialogComponent } from "./admin/user-group-admin/user-group-dialog/user-group-dialog.component";
import { UserGroupService } from "./admin/user-group.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { HealthCarePlanLobService } from './health-care-plan/health-care-plan-lob.service';
import { MockHealthCarePlanLobService } from './health-care-plan/mock-health-care-plan-lob.service';
import { HealthCarePlanComponent } from './health-care-plan/health-care-plan.component';
import { HealthCarePlanLobComponent } from './health-care-plan/health-care-plan-lob/health-care-plan-lob.component';
import { UserSearchDialogComponent } from './user/user-search-dialog/user-search-dialog.component';
import { ActivityLogJsonDialogComponent } from './activity-log/activity-log-json-dialog/activity-log-json-dialog.component';
import { StatusTrackDialogComponent } from './status-track/status-track-dialog/status-track-dialog.component';
import { StatusTrackLogDialogComponent } from './status-track/status-track-log-dialog/status-track-log-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        CommonViewModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        KeycloakAngularModule,
        MatCardModule,
        MaterialModule,
        MatGridListModule,
        MatRadioModule,
        MICaseModule,
        AttachmentModule,
        XFWFModule,
        MatDialogModule,
        MatSnackBarModule,
        NgxExtendedPdfViewerModule,
        JslModule,
        XfCommonModule
    ],
  declarations: [
    AdjustmentReasonCodeDialogComponent,
    AdjustmentReasonCodeListComponent,
    AdjustmentExplanationCodeListComponent,
    AdjustmentExplanationCodeDialogComponent,
    ApeRuleCollectionRuleListComponent,
    ApeRuleListComponent,
    ApeRuleDialogComponent,
    ApeRuleEventDefinitionListComponent,
    ApeRuleEventDefinitionListDialogComponent,
    ApeRuleEventDefinitionCreateEditComponent,
    ActivityDiaryAttachmentDialogComponent,
    ApeRuleFilterListComponent,
    ApeRuleFilterFormDialogComponent,
    ApeRuleApeRuleFilterListComponent,
    ApeRuleFilterStateDialogComponent,
    AppComponent,
    ConditionCodeComponent,
    ConditionCodeCreateFormComponent,
    ConditionCodeEditFormComponent,
    ConditionCodeDeleteFormComponent,
    DiagnosisCodeListComponent,
    DiagnosisCodeDialogComponent,
    DischargeStatusCodeListComponent,
    DischargeStatusCodeDialogComponent,
    EdiRunAttachmentDialogComponent,
    EdiRunComponent,
    EdiRunCreateComponent,
    HcpcsCodeListComponent,
    HcpcsCodeDialogComponent,
    HomeComponent,
    ItemizedLineItemAdjDialogComponent,
    ItemizedLineItemAppealAdjDialogComponent,
    OccurrenceCodeDialogComponent,
    OccurrenceCodeListComponent,
    PatientRelationshipCodeListComponent,
    PatientRelationshipCodeDialogComponent,
    ProcedureCodeListComponent,
    ProcedureCodeDialogComponent,
    ProviderListComponent,
    RevenueCodeDialogComponent,
    RevenueCodeListComponent,
    StateAdminListComponent,
    StateDialogComponent,
    SystemAdminComponent,
    TaxonomyCodeListComponent,
    TaxonomyCodeDialogComponent,
    UserDialogComponent,
    UserListComponent,
    UserSearchDialogComponent,
    UserUpdateFormComponent,
    UserProfileComponent,
    ValueCodeListComponent,
    ValueCodeDialogComponent,
    ProviderComponent,
    ProviderEditComponent,
    ProviderAddComponent,
    CompanyListComponent,
    CompanyEditComponent,
    CompanyComponent,
    CompanyAddComponent,
    ActivityLogDialogComponent,
    ActivityLogJsonDialogComponent,
    ActivityLogListComponent,
    ApeComponent,
    ApeCollectionListComponent,
    ApeCollectionDialogComponent,
    ActivityDiaryDialogComponent,
    EdiMicaseDialogComponent,
    EdiActivityDiaryDialogComponent,
    ItemizedRevisionCloneDialogComponent,
    ItemizedEditDialogComponent,
    ItemizedApeRuleEventDiaryComponent,
    PdfViewerComponent,
    ActivityLogListSubDialogComponent,
    SystemConfigurationComponent,
    TenantCompanyListComponent,
    UserManagementComponent,
    AdminUserProfileEditComponent,
    CompanyRelationalTypeAddComponent,
    ItemizedLineItemEditDialogComponent,
    DuplicateEntityDialogComponent,
    DRGCodeListComponent,
    DRGCodeDialogComponent,
    HealthCarePlanListComponent,
    HealthCarePlanDialogComponent,
    HealthCarePlanRelationalDialogComponent,
    MiCasePlanEditDialogComponent,
    HolidayEditDialogComponent,
    HolidayListComponent,
    HealthCarePlanUpdateAssignedCompanyDialogComponent,
    ItemizedRevisionEditDialogComponent,
    ApeRuleFilterPrimaryClientDialogComponent,
    ApeRuleFilterAddPrimaryClientDialogComponent,
    AddApeRuleFilterHealthCarePlanDialogComponent,
    ApeRuleFilterHealthCarePlanDialogComponent,
    StatusTrackManualAssignComponent,
    HealthCarePlanRelationalListComponent,
    UserGroupListComponent,
    UserGroupDialogComponent,
    HealthCarePlanComponent,
    HealthCarePlanLobComponent,
    StatusTrackDialogComponent,
    StatusTrackLogDialogComponent
  ],
  providers: [
    {
      provide: EnvironmentService,
      useValue: environmentValue
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, EnvironmentService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    HealthCarePlanLobService,
    ConditionCodeService,
    PatientRelationshipCodeService,
    RevenueCodeService,
    ValueCodeService,
    StateService,
    UserProfileService,
    OccurrenceCodeService,
    DischargeStatusCodeService,
    DiagnosisCodeService,
    HCPCSCodeService,
    TaxonomyCodeService,
    ProcedureCodeService,
    CompanyService,
    AdjustmentReasonCodeService,
    AdjustmentExplanationCodeService,
    ActivityLogService,
    ApeRuleCollectionService,
    ApeRuleService,
    ApeRuleEventDefinitionService,
    ApeRuleFilterService,
    ApeRuleApeRuleFilterService,
    ApeRuleFilterStateService,
    ItemizedService,
    DuplicateEntityService,
    DRGCodeService,
    HolidayService,
    ApeRuleFilterPrimaryClientService,
    ApeRuleFilterHealthCarePlanService,
    FennecSnackbarService,
    UserGroupService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
