export class UB04PayerPacket {
  id: number = -1;
  ub04Id?: number;
  companyId?: number;
  payerType?: string;
  releaseInfoCertification?: boolean;
  assignmentBenefitCertification?: boolean;
  priorPayments?: number;
  estimatedAmountDue?: number;
  patientRelationshipCode?: string;
  patientRelationshipCodeDescription?: string;
  documentControlNo?: string;
  employerName?: string;
  insuredFirstName?: string;
  insuredLastName?: string;
  insuredUniqueIdentNo?: string;
  insuredGroupName?: string;
  insuredGroupNo?: string;
  treatmentAuthorizationCode?: string;

  deleteRow: boolean = false;
}