
<div matDialogTitle class="hold-dialog-title">
  Case Hold Info
</div>

<div matDialogContent class="hold-dialog-content">

  Case is currently <span class="hold-state">{{ miCaseInfo?.onHold ? "on hold" : "off hold" }}</span>
  <br /><br />
  <div *ngIf="miCaseInfo?.onHold">
    Reason: <span class="hold-reason">{{miCaseInfo?.holdReasonUserLabel || "Unspecified"}}</span>
    <br /><br />
    To view hold review due date, please see the active ON_HOLD task
  </div>

  <form
    id="hold-dialog-form"
    *ngIf="miCaseInfo?.onHold === false"
    [formGroup]="formGroup"
    class="alt-theme"
    (keydown.enter)="submitCaseHold()"
  >

    <mat-form-field appearance="outline">
      <mat-label>Hold Reason</mat-label>
      <mat-select matInput formControlName="holdReason">
        <mat-option [value]="''">Unspecified</mat-option>
        <mat-option *ngFor="let reason of holdReasons" [value]="reason?.value">{{reason?.label}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" matTooltip="Defaults to two weeks from today if left blank" matTooltipPosition="above" matTooltipClass="hold-tooltip" matTooltipShowDelay="500">
      <mat-label>Hold Review Date (MM/DD/YYYY)</mat-label>
      <input class="input" matInput formControlName="holdReviewDate" placeholder="MM/DD/YYYY" [min]="minHoldReviewDate">
      <mat-error *ngIf="holdReviewDateControl?.hasError('dateTooEarly')">
        The review date must be in the present or future
      </mat-error>
      <mat-error *ngIf="holdReviewDateControl?.hasError('incomplete')">
        Please enter a date in MM/dd/yyyy format
      </mat-error>
    </mat-form-field>
  </form>
</div>

<div matDialogActions class="hold-dialog-actions">
  <button
    id="submit-case-hold"
    type="button"
    mat-button
    color="accent"
    (click)="submitCaseHold()"
    [class.case-on-hold]="miCaseInfo?.onHold"
  >
    {{ miCaseInfo?.onHold ? "Take off hold" : "Put on hold" }}
  </button>
</div>
