import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ItemizedLineItemService } from '../itemized-line-item.service';
import { AddCommentComponent } from '@app/comments/add-comment/add-comment.component';
import {
  BaseResponse,
  CodeValidationService,
  FennecSnackbarService,
  ItemizedLineItemAdjustmentPacket
} from "xf-common";


@Component({
  selector: 'app-itemized-line-item-adj-dialog',
  templateUrl: './itemized-line-item-adj-dialog.component.html',
  styleUrls: ['./itemized-line-item-adj-dialog.component.scss']
})
export class ItemizedLineItemAdjDialogComponent implements OnInit, AfterViewInit {

  formGroup: FormGroup = new FormGroup({});

  // Line Item Information Display
  liServiceDateString = "01/01/1900";
  liDescription = "Unknown";

  internalReasonCode = false;
  internalExplanationCode = false;

  ids = [];
  addCommentVisible = false;

  constructor(
    public dialogRef: MatDialogRef<ItemizedLineItemAdjDialogComponent>,
    private snack: FennecSnackbarService,
    private itemizedLineItemService: ItemizedLineItemService,
    private codeValidationService: CodeValidationService,
    public matDialog: MatDialog,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.liServiceDateString = data.liServiceDateString;
    this.liDescription = data.liDescription;
    this.populateFormGroup(data);
    if(data.ids) {
      this.ids = data.ids;
    }
  }

  populateFormGroup(data: any) {
    const newFormGroup = new FormGroup({
      adjustedUnits: new FormControl(data.adjustedUnits,
        data.billedAmount >= 0 ? [Validators.required, Validators.min(0)] : [Validators.required]),
      adjustedAmount: new FormControl(data.adjustedAmount,
        data.billedAmount >= 0 ? [Validators.required, Validators.min(0)] : [Validators.required]),
      adjustmentReasonCode: new FormControl(data.adjustmentReasonCode, Validators.required),
      adjustmentReasonCodeDescription: new FormControl(data.adjustmentReasonCodeDescription),
      adjustmentExplanationCode: new FormControl(data.adjustmentExplanationCode, Validators.required),
      adjustmentExplanationCodeDescription: new FormControl(data.adjustmentExplanationCodeDescription)
    });

    newFormGroup.controls['adjustmentReasonCodeDescription'].disable();

    newFormGroup.controls['adjustmentReasonCode'].valueChanges.subscribe(() => {
      this.validateAdjustmentReasonCode();
    })


    newFormGroup.controls['adjustmentExplanationCodeDescription'].disable();

    newFormGroup.controls['adjustmentExplanationCode'].valueChanges.subscribe(() => {
      this.validateAdjustmentExplanationCode();
    })

    this.formGroup = newFormGroup;

    if(data.adjustmentReasonCode) {
      this.validateReasonCodeOnLoad(data.adjustmentReasonCode);
    }
    if(data.adjustmentExplanationCode) {
      this.validateAdjustmentExplanationCodeOnLoad(data.adjustmentExplanationCode);
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let dto: ItemizedLineItemAdjustmentPacket = new ItemizedLineItemAdjustmentPacket();
    dto.ids = this.data.ids;
    dto.adjustedUnits = this.formGroup.controls['adjustedUnits'].value;
    dto.adjustedAmount = this.formGroup.controls['adjustedAmount'].value;

    // dto.adjustmentExplanationCode = null;
    // dto.adjustmentReasonCode = null;
    dto.adjustmentExplanationCode = this.formGroup.controls['adjustmentExplanationCode'].value;
    dto.adjustmentReasonCode = this.formGroup.controls['adjustmentReasonCode'].value;

    this.itemizedLineItemService.putItemizedLineItemAdjustment(dto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
          ids: this.data.ids
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  validateAdjustmentReasonCode() {
    let arcFormControl: FormControl = this.formGroup.controls['adjustmentReasonCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['adjustmentReasonCodeDescription'] as FormControl;

    if (!arcFormControl.pristine) {
      if (arcFormControl.value.length < 1) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("");
        arcFormControl.setErrors(null);
        this.internalReasonCode = false;
      }else if (arcFormControl.value.length > 4) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("Invalid");
        arcFormControl.setErrors({'incorrect': true});
        this.internalReasonCode = false;
      } else {
        this.codeValidationService.getAdjustmentReasonCode(arcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            arcFormControl.markAsPristine();
            arcFormControl.setErrors({'incorrect': true});
            this.internalReasonCode = false;
          } else {
            if (response.data !== null && response.data.adjustmentReasonType === "LINE_ITEM_ADJUSTMENT") {
              arcFormControl.markAsPristine();
              arCodeDescriptionFormControl.setValue(response.data.description);
              arcFormControl.setErrors(null);
              this.internalReasonCode = response.data.internal;
            } else {
              arCodeDescriptionFormControl.setValue("Invalid");
              arcFormControl.markAsPristine();
              arcFormControl.setErrors({'incorrect': true});
              this.internalReasonCode = false;
            }
          }
        });
      }
    }
  }

  validateReasonCodeOnLoad(code:string) {
    let arcFormControl: FormControl = this.formGroup.controls['adjustmentReasonCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['adjustmentReasonCodeDescription'] as FormControl;

    this.codeValidationService.getAdjustmentReasonCode(code).subscribe((response: BaseResponse) => {
      if (response.hasErrors) {
        arcFormControl.markAsPristine();
        arcFormControl.setErrors({'incorrect': true});
        this.internalReasonCode = false;
      } else {
        if (response.data !== null && response.data.adjustmentReasonType === "LINE_ITEM_ADJUSTMENT") {
          arcFormControl.markAsPristine();
          arCodeDescriptionFormControl.setValue(response.data.description);
          arcFormControl.setErrors(null);
          this.internalReasonCode = response.data.internal;
        } else {
          arCodeDescriptionFormControl.setValue("Invalid");
          arcFormControl.markAsPristine();
          arcFormControl.setErrors({'incorrect': true});
          this.internalReasonCode = false;
        }
      }
    });
  }

  validateAdjustmentExplanationCode() {
    let aecFormControl: FormControl = this.formGroup.controls['adjustmentExplanationCode'] as FormControl;
    let aeCodeDescriptionFormControl: FormControl = this.formGroup.controls['adjustmentExplanationCodeDescription'] as FormControl;
    let arcFormControl: FormControl = this.formGroup.controls['adjustmentReasonCode'] as FormControl;

    if (!aecFormControl.pristine) {
      if (aecFormControl.value.length < 1) {
        aecFormControl.markAsPristine();
        aeCodeDescriptionFormControl.setValue("");
        aecFormControl.setErrors(null);
        this.internalExplanationCode = false;
      }else if (aecFormControl.value.length > 7) {
        aecFormControl.markAsPristine();
        aeCodeDescriptionFormControl.setValue("Invalid");
        aecFormControl.setErrors({'incorrect': true});
        this.internalExplanationCode = false;
      } else {
        this.codeValidationService.getAdjustmentExplanationCode(aecFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            aecFormControl.markAsPristine();
            aecFormControl.setErrors({'incorrect': true});
            this.internalExplanationCode = false;
          } else {
            if (response.data != null && response.data.adjustmentExplanationType === "LINE_ITEM_ADJUSTMENT") {
              aecFormControl.markAsPristine();
              aeCodeDescriptionFormControl.setValue(response.data.description);
              if (response?.data?.adjustmentReasonCode != null && response?.data?.adjustmentReasonCode != '' && (arcFormControl.value == null || arcFormControl.value == '')) {
                arcFormControl.setValue(response.data.adjustmentReasonCode);
                arcFormControl.markAsDirty();
                this.validateAdjustmentReasonCode();
              }
              aecFormControl.setErrors(null);
              this.internalExplanationCode = response.data.internal;
            } else {
              aeCodeDescriptionFormControl.setValue("Invalid");
              aecFormControl.markAsPristine();
              aecFormControl.setErrors({'incorrect': true});
              this.internalExplanationCode = false;
            }
          }
        });
      }
    }
  }

  validateAdjustmentExplanationCodeOnLoad(code:string) {
    let arcFormControl: FormControl = this.formGroup.controls['adjustmentExplanationCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['adjustmentExplanationCodeDescription'] as FormControl;

    this.codeValidationService.getAdjustmentExplanationCode(code).subscribe((response: BaseResponse) => {
      if (response.hasErrors) {
        arcFormControl.markAsPristine();
        arcFormControl.setErrors({'incorrect': true});
        this.internalExplanationCode = false;
      } else {
        if (response.data !== null && response.data.adjustmentExplanationType === "LINE_ITEM_ADJUSTMENT") {
          arcFormControl.markAsPristine();
          arCodeDescriptionFormControl.setValue(response.data.description);
          arcFormControl.setErrors(null);
          this.internalExplanationCode = response.data.internal;
        } else {
          arCodeDescriptionFormControl.setValue("Invalid");
          arcFormControl.markAsPristine();
          arcFormControl.setErrors({'incorrect': true});
          this.internalExplanationCode = false;
        }
      }
    });
  }

  addCommentDialog = () => {
    let mode = "MULTI";

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "65vw";
    matDialogConfig.data = {
      mode,
      comment: null,
      relationalType: "ITEMIZED_LINE_ITEM",
      relatedId: null,
      relatedIdList: this.data.ids
    };

    const dialogRef = this.matDialog.open(AddCommentComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      // if (this.selectedLineItems.length > 0) {
      //   this.itemizedLineItemGridComponent?.setScrollToLineItemId(this.selectedLineItems[0]);
      // }
      // this.getLineItemQuery();
    })
  }

  toggleEmbeddedComments = () => {
    this.addCommentVisible = !this.addCommentVisible;
  }

  zeroAllowedAmounts() {
    this.formGroup.controls['adjustedUnits'].setValue(0);
    this.formGroup.controls['adjustedAmount'].setValue(0);   
  }
}
