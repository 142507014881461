import { NgModule } from "@angular/core";
import { XFWFTaskListComponent } from "@app/xfwf/xfwf-task-list/xfwf-task-list.component";
import { XFWFActionDialogComponent } from "@app/xfwf/xfwf-action-dialog/xfwf-action-dialog.component";
import { CommonModule } from "@angular/common";
import { CommonViewModule, MaterialModule } from 'xf-common';
import { XfwfCreateManualComponent } from './xfwf-create-manual/xfwf-create-manual.component';

@NgModule({
  imports: [CommonModule, CommonViewModule, MaterialModule],
  exports: [XFWFTaskListComponent, XFWFActionDialogComponent],
  declarations: [XFWFTaskListComponent, XFWFActionDialogComponent, XfwfCreateManualComponent],
  providers: []
})
export class XFWFModule {
}
