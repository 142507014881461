<mat-dialog-content class="content-wrapper alt-theme">
    <h1 mat-dialog-title>Itemized Line Item Appeal Adjustment</h1>
    <div class="line-item-adj-dialog-sub-heading">
      {{liServiceDateString}} {{liDescription}}
      <span *ngIf="isMulti()" style="margin-left: 3px; color:darkblue"> <---- First Line Item - [* MULTI *] You will be updating {{ids.length}} line items!</span>
    </div>

    <div class="adj-display-div">
      <div class="adj-display-item-label">
        Adj Reason:
      </div>
      <div class="adj-display-item-code">
        {{liData.adjustmentReasonCode}}
      </div> 
      <div class="adj-display-item-desc">
        {{liData.adjustmentReasonCodeDescription}}
      </div> 
      <div class="adj-display-item-label">
        Adj Explanation:
      </div>
      <div class="adj-display-item-code">
        {{liData.adjustmentExplanationCode}}
      </div> 
      <div class="adj-display-item-desc">
        {{liData.adjustmentExplanationCodeDescription}}
      </div>                
    </div>

    <div class="adj-form">
      <form [formGroup]="formGroup" class="container">
              
        <div>
          <mat-form-field appearance="outline" class="code-input">
            <mat-label>Appeal Reason Code</mat-label>
            <input matInput formControlName="appealReasonCode">
          </mat-form-field>

          <mat-form-field appearance="fill" class="code-description">
            <mat-label>Appeal Reason Code Description</mat-label>
            <input matInput formControlName="appealReasonCodeDescription">
          </mat-form-field>
        </div>

        <div style="margin-top: 2px;">
          <mat-form-field appearance="outline" class="code-input">
            <mat-label>Appeal Explanation Code</mat-label>
            <input matInput formControlName="appealExplanationCode">
          </mat-form-field>

          <mat-form-field appearance="fill" class="code-description">
            <mat-label>Appeal Explanation Code Description</mat-label>
            <textarea matInput cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5" formControlName="appealExplanationCodeDescription"></textarea>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Appeal Decision</mat-label>
            <mat-select formControlName="appealDecision">
              <mat-option *ngFor="let ad of appealDecisionCodes" [value]="ad">
                {{ ad }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-button mat-raised-button color="accent" (click)="onClearAppealDecision()" style="margin-left: 5px">
            <mat-icon>clear</mat-icon>
          </button>

        </div>

      </form>
    </div>

    <app-add-comment [hidden]="!addCommentVisible" mode="MULTI" relationalType="ITEMIZED_LINE_ITEM" [relatedIdList]="ids" [embeddedInDialog]="true"></app-add-comment>
  </mat-dialog-content>
  
  <mat-dialog-actions class="fennec-controls alt-theme">
    <button mat-stroked-button (click)="onClearAppealAdjustment()">
      <mat-icon>clear</mat-icon> Clear Appeal Adjustment
  </button>

    <button mat-stroked-button (click)="toggleEmbeddedComments()">
      <mat-icon>{{addCommentVisible ?  "comments_disabled" : "add_comment"}}</mat-icon>
      {{addCommentVisible ? "Close Comment" : "Add Comment"}}
    </button>

    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Save
    </button>
    
    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
  </mat-dialog-actions>
  
  
