import { EnvironmentI } from 'xf-common';

export const environmentValue: EnvironmentI = {
  app: "fennec",
  production: true,
  baseApiUrl: "https://api.dev.xorfox.cloud/api/",
  baseApiAttachmentUrl: "https://api-attachment.dev.xorfox.cloud/api/",
  keycloakServerUrl: "https://keycloak.dev.xorfox.cloud/",
  keycloakRealmName: "FennecRealm",
  keycloakClientId: "fennec-login",
  keycloakRedirectUri: "https://fennec.dev.xorfox.cloud/*",
};
