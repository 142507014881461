import { Deserializable } from "../deserializable";


export class ItemizedLineItemAppealAdjustmentPacket extends Deserializable<ItemizedLineItemAppealAdjustmentPacket> {
  ids?: number [] = [];
  clearAppealAdjustment: boolean = false;
  adjustedUnits?: number = 0;
  adjustedAmount?: number = 0;
  appealReasonCode?: string | null;
  appealExplanationCode?: string | null;
  appealDecision?: string | null;
}
