import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent, DateUtil, FennecSnackbarService, Logger, MICaseService, SimpleObject } from 'xf-common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

@Component({
  selector: 'case-hold-info',
  templateUrl: './case-hold-dialog.component.html',
  styleUrls: ['./case-hold-dialog.component.scss']
})
export class CaseHoldDialogComponent extends BaseComponent implements OnInit {
  log = new Logger("CaseHoldDialogComponent");
  id?: number;

  formGroup: FormGroup;
  minHoldReviewDate = DateUtil.getDenverJSDateNow();
  holdReviewDateControl?: AbstractControl;
  holdReasons: any;
  holdReasonMap: SimpleObject = {};

  constructor(
    protected snack: FennecSnackbarService,
    public dialogRef: MatDialogRef<CaseHoldDialogComponent>,
    private miCaseService: MICaseService,
    @Inject(MAT_DIALOG_DATA) public miCaseInfo: any
  ) {
    super();

    this.id = miCaseInfo?.id;
    const caseReviewDueDate = DateUtil.fennecDBStringToInputDateString(miCaseInfo?.caseReviewDueDate);

    this.formGroup = new FormGroup({
      holdReviewDate: new FormControl(caseReviewDueDate ?? "", this.validateHoldReviewDate()),
      holdReason: new FormControl(miCaseInfo.holdReason ?? "")
    });

    this.getHoldReasons();
  }

  ngOnInit(): void {
    this.holdReviewDateControl = this.formGroup.controls["holdReviewDate"];
  }

  getHoldReasons() {
    this.performXFRequest({
      requestDescription: "Get hold reasons",
      requestFn: this.miCaseService.getHoldReasons,
      onSuccess: holdReasons => {
        this.holdReasons = holdReasons;
      },
      onError: err => this.snack.showErrorSnack(err)
    });
  }

  validateHoldReviewDate(): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      const val = ctrl.value;
      if (val == null || val == "") {
        // not a required field; no error
        return null;
      }

      if (val.length != 10) {
        // value entered does not match format length
        return {incomplete: true};
      }

      const dateEntered = DateUtil.inputDateStringToJSDate(val);
      if (dateEntered == null) {
        // value failed to parse as MM/dd/yyyy
        return {incomplete: true};
      }

      if (dateEntered < this.minHoldReviewDate) {
        // value entered is too early
        return {dateTooEarly: true};
      }

      // value entered is valid
      return null;
    }
  }

  submitCaseHold () {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid && this.miCaseInfo?.onHold === false) {
      return;
    }

    const holdReason = this.formGroup.value?.holdReason;
    const data: SimpleObject = {
      id: this.id,
      holdReason: holdReason === "" ? null : holdReason,
      holdReviewDate: DateUtil.inputDateStringToFennecDBDateString(this.formGroup.value?.holdReviewDate),
      desiredHoldState: !this.miCaseInfo?.onHold
    };

    this.performXFRequest({
      requestDescription: "Toggle Case Hold",
      requestFn: this.miCaseService.toggleMICaseHold,
      fnParams: [data],
      onSuccess: (data) => {
        this.dialogRef.close(data);
        super.showSuccessSnack("Success");
        this.miCaseService.emitRefreshCaseInfo();
      },
      onError: (error) => {
        super.showWarningSnack("Failed to edit case hold info.\n" + error);
        this.log.debug("Failed to edit case hold info", error);
      }
    });
  }

}
