import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  UB04StatusTrackBaseDialogComponent
} from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { Ub04AddCompanyComponent } from './ub04-add-company/ub04-add-company.component';
import { Ub04EditPayerComponentComponent } from './ub04-edit-payer-component/ub04-edit-payer-component.component';
import { UB04PayerPacket } from "../../model/net/ub04-payer-packet";
import { UB04PayerBulkPacket } from "../../model/net/ub04-payer-bulk-packet";
import { StatusTrackService } from "../../status-track/status-track.service";
import { NameSearchPromptComponent } from "../../search/name-search-prompt/name-search-prompt.component";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from "../../util/logger";


@Component({
  selector: 'app-ub04-payer-dialog',
  templateUrl: './ub04-payer-dialog.component.html',
  styleUrls: ['./ub04-payer-dialog.component.scss']
})
export class Ub04PayerDialogComponent extends UB04StatusTrackBaseDialogComponent implements OnInit, AfterViewInit {
  override log = new Logger("Ub04PayerDialogComponent");

  primaryFormGroup!: FormGroup;
  // Server data in original format from the server.
  ub04Providers: any [] = [];
  // Formatted structured data for data entry experience.
  existingUB04Providers: any [] = [];

  changesMade: boolean = false;

  primaryCompany: any = null;

  deletedPayers: UB04PayerPacket[] = [];

  // modes - "EXISTING", "SEARCH_{primary/attending/operating}"
  displayMode: string = "EXISTING";

  @ViewChild('companyNameSearch')
  companyNameSearch!: NameSearchPromptComponent;

  @ViewChild('companyAddComponent')
  companyAddComponent!: Ub04AddCompanyComponent;

  @ViewChild('primaryPayerComponent')
  primaryPayerComponent!: Ub04EditPayerComponentComponent;

  @ViewChild('secondaryPayerComponent')
  secondaryPayerComponent!: Ub04EditPayerComponentComponent;

  @ViewChild('tertiaryPayerComponent')
  tertiaryPayerComponent!: Ub04EditPayerComponentComponent;

  constructor(
    public dialogRef: MatDialogRef<Ub04PayerDialogComponent>,
    public matDialog: MatDialog,
    private statusTrackService: StatusTrackService,
    override snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_PAYER, statusTrackService);
    this.constructFormGroup();
   }

  ngAfterViewInit(): void {
    if (this.companyNameSearch !== null && this.companyNameSearch !== undefined) {
      this.companyNameSearch?.ub04PayerSelected?.subscribe((msgObj) => {
        if (msgObj !== null && msgObj !== undefined) {
          this.selectCompany(msgObj);
        }
      });
    }

    const editorArray = this.getEditorArray();

    editorArray.forEach(editorComponent => {
      if (editorComponent !== null && editorComponent !== undefined) {
        editorComponent?.companySearch?.subscribe((payerType) => {
          if (payerType !== null && payerType !== undefined) {
            this.setDisplayMode(`SEARCH_${payerType}`);
          }
        });

        editorComponent?.deletePayer?.subscribe((msgObj) => {
          if(msgObj !== null && msgObj !== undefined) {
            this.handleDelete(msgObj);
          }
        })
      }
    })
  }

  ngOnInit(): void {
    this.fetchData();
  }

  getEditorArray(): Ub04EditPayerComponentComponent[] {
    const editorArray = [];
    editorArray.push(this.primaryPayerComponent);
    editorArray.push(this.secondaryPayerComponent);
    editorArray.push(this.tertiaryPayerComponent);
    return editorArray;
  }

  fetchData(): void {
    this.ub04Service.getUb04Payers(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        response.data.forEach((existingPayer: UB04PayerPacket) => {
          if(existingPayer.payerType === "PRIMARY"){
            if (this.primaryPayerComponent !== null && this.primaryPayerComponent !== undefined) {
              this.primaryPayerComponent.populateExistingProvider(existingPayer);
            }
          }

          if(existingPayer.payerType === "SECONDARY"){
            if (this.secondaryPayerComponent !== null && this.secondaryPayerComponent !== undefined) {
              this.secondaryPayerComponent.populateExistingProvider(existingPayer);
            }
          }

          if(existingPayer.payerType === "TERTIARY"){
            if (this.tertiaryPayerComponent !== null && this.tertiaryPayerComponent !== undefined) {
              this.tertiaryPayerComponent.populateExistingProvider(existingPayer);
            }
          }
        })
      }
    });
  }

  constructFormGroup() {
    this.primaryFormGroup = new FormGroup({
      releaseInfoCertification: new FormControl(),
      assignmentBenefitCertification: new FormControl(),
      priorPayments: new FormControl(),
      estimatedAmountDue: new FormControl(),
      insuredFirstName: new FormControl(),
      insuredLastName: new FormControl(),
      insuredUniqueIdentNo: new FormControl(),
      insuredGroupName: new FormControl(),
      insuredGroupNo: new FormControl(),
      treatmentAuthorizationCode: new FormControl(),
      documentControlNo: new FormControl(),
      employerName: new FormControl(),
    });
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  isSearch(): boolean {
    return this.displayMode.startsWith("SEARCH");
  }

  setDisplayMode(mode: string) {
    this.displayMode = mode;
  }

  selectCompany(paramObj: any) {

    if(this.displayMode.endsWith("PRIMARY")) {
      this.primaryPayerComponent.selectedCompany = paramObj;
    }

    if(this.displayMode.endsWith("SECONDARY")) {
      this.secondaryPayerComponent.selectedCompany = paramObj;
    }

    if(this.displayMode.endsWith("TERTIARY")) {
      this.tertiaryPayerComponent.selectedCompany = paramObj;
    }

    // this.primaryCompany = paramObj;
    this.changesMade = true;
    this.setDisplayMode("EXISTING");
  }

  addFormGroupLine(companyObj: any) {
    let newFormGroup: FormGroup = new FormGroup({
      companyId: new FormControl(companyObj.id),
      ub04Id: new FormControl()
    })
  }

  onSave() {
    // Creates an array of all editor components
    const editorArray = this.getEditorArray();

    const bulkPacket = new UB04PayerBulkPacket();
    bulkPacket.ub04Id = this.data.ub04Id;

    // Adds deletedPayers to bulk packet
    // bulkPacket.ub04PayerPackets.concat(this.deletedPayers);
    this.deletedPayers.forEach(deletedPayer => {
      bulkPacket.ub04PayerPackets.push(deletedPayer);
    })

    // loops through editor component array and populates bulk packet
    editorArray.forEach(editorComponent => {
      if(editorComponent.selectedCompany !== null) {
        const formGroup: FormGroup = editorComponent.formGroup;
        const controls = formGroup.controls;

        const packet = new UB04PayerPacket();

        if(editorComponent.existingUB04Payer != null){
          packet.id = editorComponent.existingUB04Payer.id;
          packet.deleteRow = editorComponent.existingUB04Payer.deleteRow;
        }

        packet.ub04Id = parseInt(this.data.ub04Id);
        packet.payerType = editorComponent.payerType;
        packet.companyId = editorComponent.selectedCompany.id;
        packet.releaseInfoCertification = controls['releaseInfoCertification'].value ? true : false;
        packet.assignmentBenefitCertification = controls['assignmentBenefitCertification'].value ? true : false;

        packet.priorPayments = controls['priorPayments'].value;
        if(controls['estimatedAmountDue'].value) {
          packet.estimatedAmountDue = controls['estimatedAmountDue'].value;
        }else {
          packet.estimatedAmountDue = 0;
        }
        packet.patientRelationshipCode = controls['patientRelationshipCode'].value;

        packet.documentControlNo = controls['documentControlNo'].value;
        packet.employerName = controls['employerName'].value;
        packet.insuredFirstName = controls['insuredFirstName'].value;
        packet.insuredLastName = controls['insuredLastName'].value;
        packet.insuredUniqueIdentNo = controls['insuredUniqueIdentNo'].value;
        packet.insuredGroupName = controls['insuredGroupName'].value;
        packet.insuredGroupNo = controls['insuredGroupNo'].value;
        packet.treatmentAuthorizationCode = controls['treatmentAuthorizationCode'].value;

        bulkPacket.ub04PayerPackets.push(packet);
      }
    })

    this.ub04Service.putUb04PayersBulkUpdate(bulkPacket).subscribe(response => {
      if(response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      }else {
        const returnObj = {
          confirm: true
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack('Successfully Updated!');
      }
    })
  }

  handleDelete(msgObj:any) {
    // Adds existing payer to deletedPayers list if it exists for use in onSave
    if(msgObj.existingPayer !== null && msgObj.existingPayer !== undefined) {
      this.deletedPayers.push(msgObj.existingPayer);
    }

    // If Primary Payer is deleted, checks to see if there is a secondary and tertiary and then moves them each up to their respective new positions
    // ie. secondary becomes Primary, tertiary becomes Secondary
    if(msgObj.payerType === "PRIMARY") {

      if(this.secondaryPayerComponent.selectedCompany !== null) {
        // this.primaryPayerComponent.formGroup = this.secondaryPayerComponent.formGroup;
        this.primaryPayerComponent.populateFormGroupFromExistingFormGroup(this.secondaryPayerComponent.formGroup);
        this.primaryPayerComponent.selectedCompany = this.secondaryPayerComponent.selectedCompany;
        this.primaryPayerComponent.existingUB04Payer = this.secondaryPayerComponent.existingUB04Payer;

        if(this.tertiaryPayerComponent.selectedCompany !== null) {
          // this.secondaryPayerComponent.formGroup = this.tertiaryPayerComponent.formGroup;
          this.secondaryPayerComponent.populateFormGroupFromExistingFormGroup(this.tertiaryPayerComponent.formGroup);
          this.secondaryPayerComponent.selectedCompany = this.tertiaryPayerComponent.selectedCompany;
          this.secondaryPayerComponent.existingUB04Payer = this.tertiaryPayerComponent.existingUB04Payer;

          // Clears tertiary component
          this.tertiaryPayerComponent.formGroup.reset();
          this.tertiaryPayerComponent.selectedCompany = null;
          this.tertiaryPayerComponent.existingUB04Payer = null;
        }else {
          // Clears secondary component if tertiary component does not exist
          this.secondaryPayerComponent.formGroup.reset();
          this.secondaryPayerComponent.selectedCompany = null;
          this.secondaryPayerComponent.existingUB04Payer = null;
        }
      }else {
        // Clears primary component if Secondary & Tertiary components are not being used
        this.primaryPayerComponent.formGroup.reset();
        this.primaryPayerComponent.selectedCompany = null;
        this.primaryPayerComponent.existingUB04Payer = null;
      }
    }

    if(msgObj.payerType === "SECONDARY") {
      if(this.tertiaryPayerComponent.selectedCompany !== null) {
        this.secondaryPayerComponent.populateFormGroupFromExistingFormGroup(this.tertiaryPayerComponent.formGroup);
        this.secondaryPayerComponent.selectedCompany = this.tertiaryPayerComponent.selectedCompany;
        this.secondaryPayerComponent.existingUB04Payer = this.tertiaryPayerComponent.existingUB04Payer;

        // Clears tertiary component
        this.tertiaryPayerComponent.formGroup.reset();
        this.tertiaryPayerComponent.selectedCompany = null;
        this.tertiaryPayerComponent.existingUB04Payer = null;
      }else {
        // Clears secondary component if tertiary component does not exist
        this.secondaryPayerComponent.formGroup.reset();
        this.secondaryPayerComponent.selectedCompany = null;
        this.secondaryPayerComponent.existingUB04Payer = null;
      }
    }

    if(msgObj.payerType === "TERTIARY") {
      this.tertiaryPayerComponent.formGroup.reset();
      this.tertiaryPayerComponent.selectedCompany = null;
      this.tertiaryPayerComponent.existingUB04Payer = null;
    }
  }

  checkValidity() {
    if(this.primaryPayerComponent?.selectedCompany !== null && !this.primaryPayerComponent?.formGroup?.valid) {
      return false;
    }

    if(this.secondaryPayerComponent?.selectedCompany !== null && !this.secondaryPayerComponent?.formGroup?.valid) {
      return false;
    }

    if(this.tertiaryPayerComponent?.selectedCompany !== null && !this.tertiaryPayerComponent?.formGroup?.valid) {
      return false;
    }

    return true;
  }

  getFormattedPayerTypeForSearch() {
    if(this.displayMode.startsWith("SEARCH")) {
      let type = this.displayMode.split("_")[1];
      let firstLetter = type[0];
      let restOfTheWord = type.slice(1).toLowerCase();
      return firstLetter + restOfTheWord + " ";
    }
    return " "
  }
}
