import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  ItemizedLineItemAdjDialogComponent
} from '../itemized-line-item-adj-dialog/itemized-line-item-adj-dialog.component';
import { ItemizedLineItemService } from '../itemized-line-item.service';
import { ItemizedLineItemGridComponent } from '../itemized-line-item-grid/itemized-line-item-grid.component';
import { ItemizedRevisionService } from '@app/itemized/itemized-revision/itemized-revision.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {
  ItemizedApeRuleEventDiaryComponent
} from '../itemized-ape-rule-event-diary/itemized-ape-rule-event-diary/itemized-ape-rule-event-diary.component';
import { MatCalendar, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { CommentListDialogComponent } from '@app/comments/comment-list-dialog/comment-list-dialog.component';
import {debounceTime, Subject, Subscription, takeUntil} from 'rxjs';
import {
  ItemizedLineItemEditDialogComponent
} from '../itemized-line-item-edit-dialog/itemized-line-item-edit-dialog.component';
import {
  ItemizedRevisionRevenueCodeSummaryComponent
} from '@app/itemized/itemized-revision/itemized-revision-revenue-code-summary/itemized-revision-revenue-code-summary.component';
import { UserProfileService } from '@app/user/user-profile.service';
import { AddCommentComponent } from '@app/comments/add-comment/add-comment.component';
import {
  BaseComponent,
  DateUtil,
  FennecSnackbarService,
  Logger,
  PagedResponse,
  SingleChoiceDialogComponent,
  Ub04ReviewComponent
} from "xf-common";
import { ItemizedLineItemAppealAdjDialogComponent } from '../itemized-line-item-appeal-adj-dialog/itemized-line-item-appeal-adj-dialog.component';
import {ColumnDefinition} from "tabulator-tables";


@Component({
  selector: 'app-itemized-line-item',
  templateUrl: './itemized-line-item.component.html',
  styleUrls: ['./itemized-line-item.component.scss']
})
export class ItemizedLineItemComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("ItemizedLineItemComponent");

  // This is required. All significant logic drives off of this value.
  @Input()
  itemizedRevisionId: string = "-1";

  // Linked UB04 id if it exists. Can be null.
  linkedItemizedUb04Id: string | null = null;

  // Itemized id (parent of the Itemized Revision)
  itemizedId: number = -1;

  // MI Case id
  miCaseId: number = -1;

  // iliEditorMode - triggered off of ItemizedRevisionReviewType (server side) - isAppeal() flag
  // Default: CLAIM_REVIEW
  // APPEAL is the only other option at this time. This may be extended in the future.
  iliEditorMode: string = "CLAIM_REVIEW";

  // Number of line Items on the currently loaded Itemized Revision
  lineItemCount: number = 0;
  totalBilledAmount: number = 0;
  totalAdjustedAmount: number = 0;
  totalUnits: number = 0;
  totalAdjustedUnits: number = 0;

  // Indicates whether or not this revision has been run through APE or not.
  apeProcessed: boolean = false;

  // Unique Service Date Strings for Itemized Revision - gleaned from Line Item data
  serviceDateStrings: string [] = [];

  // Unique Revenue Code Strings - for filtering
  revenueCodeStrings: string [] = [];

  // Unique Mapped Revenue Code Strings - for filtering
  mappedRevenueCodeStrings: string [] = [];

  reasonCodeFilterOptions: any[] = [];
  explanationCodeFilterOptions: any[] = [];

  // Line Item Query variable parameters
  lineItemQueryFormGroup: FormGroup;

  // Sort Order Form Group
  sortOrderFormGroup: FormGroup;

  // Description filter FormGroup
  descriptionFilterFormGroup: FormGroup;

  // Line Item Query filterNo
  filterNo: number = 0;

  // Line Item Query pagination
  totalRowCount: number = 0;
  defaultPageSize: number = 100;
  defaultPageSizeOptions = [50, 100, 500, 1000, 10000, 99999];

  // Note: this serves 2 purposes:
  // 1) Allow the user to choose their default page size every time they use the line item editor.
  // 2) To delay the rendering of the first grid to prevent a race condition between tabulator js and angular
  //    which causes tabulator js to exceed max call stack.
  userDefaultPageSize: number = -1;

  // Deterines which options (viewpane) is shown on the left pane view
  leftNavViewIdx: number = 0;

  // Determines which view the user would like to see. The view is actually 'shown' in the right nav panel, but selected in the left.
  leftNavSelectedViewIdx: number = 0;

  // Service Date Picker
  selected: any;

  navigationOpen:boolean = true;

  @ViewChild('serviceDatePicker')
  serviceDatePicker?: MatCalendar<Date>;

  @ViewChild(MatPaginator)
  _paginator?: MatPaginator;

  get paginator(): MatPaginator {
    return this._paginator ?? {} as MatPaginator;
  }

  // Line Item Editor Column Config - sent to Line Item Grid component for formatting display
  // in grid.
  private columnConfig: any[] = [];

  private defaultColumnConfig: ColumnDefinition[] = [
    {title: "SortDate", field: "serviceDateString", sorter: "string", width: "6%",
      editable: false, visible: true},
    {title: "Date", field: "serviceDateStringFormatted", sorter: "date", width: "100",
      editable: false},
    {title: "Rev", field: "revenueCode", sorter: "string", width: "80", editable: false},
    {title: "mRev", field: "mappedRevenueCode", sorter: "string", width: "80", editable: false},
    {title: "CPT", field: "cptCode", sorter: "string", editable: false, width: "100"},
    {title: "Description", field: "description", sorter: "string", editable: false},
    {title: "Units", field: "units", sorter: "number", width: "100",
      editable: false, hozAlign: "right"},
    {title: "AdjUnits", field: "adjustedUnits", sorter: "number", width: "100",
      editable: false, hozAlign: "right"},
    {title: "Billed", field: "billedAmount", sorter: "number", width: "6%",
      editable: false, hozAlign: "right", formatter: "money",
      formatterParams: {
        symbol:"$",
      }},
    {title: "Adjusted", field: "adjustedAmount", sorter: "number", width: "6%",
      editable: false, hozAlign: "right", formatter: "money",
      formatterParams: {
        symbol:"$",
    }},
    {title: "PreExAdjAmt", field: "preExAdjustedAmount", sorter: "number", width: "6%",
      editable: false, hozAlign: "right", formatter: "money",
      formatterParams: {
        symbol:"$",
    }},
    {title: "PreExExplanation", field: "preExExplanation", sorter: "string", editable: false},
    {title: "BillPageNo", field: "billPageNo", sorter: "number", width: "100",
      editable: false, hozAlign: "right"},
    {title: "BillLineNo", field: "billLineNo", sorter: "number", width: "100",
      editable: false, hozAlign: "right"},
    {title: "APE", field: "apeAdjustedFormatted", sorter: "string", width: "6%",
      hozAlign: "center", editable: false, formatterParams: {
        action:"ape",
      }},
    {title: "A/R", field: "adjustmentReasonCode", sorter: "string", width: "80",
    hozAlign: "center", editable: false},
    {title: "A/R Desc", field: "adjustmentReasonCodeDescription", sorter: "string", width: "6%",
      hozAlign: "center", editable: false},
    {title: "A/E", field: "adjustmentExplanationCode", sorter: "string",
      hozAlign: "center", width: "80", editable: false},
    {title: "A/E Desc", field: "adjustmentExplanationCodeDescription", sorter: "string",
      hozAlign: "center", width: "6%", editable: false},
    {title: "App/R", field: "appealReasonCode", sorter: "string", width: "80",
      hozAlign: "center", editable: false},
    {title: "App/R Desc", field: "appealReasonCodeDescription", sorter: "string", width: "6%",
      hozAlign: "center", editable: false},
    {title: "App/E", field: "appealExplanationCode", sorter: "string",
      hozAlign: "center", width: "80", editable: false},
    {title: "App/E Desc", field: "appealExplanationCodeDescription", sorter: "string",
      hozAlign: "center", width: "6%", editable: false},
    {title: "App Decision", field: "appealDecision", sorter: "string",
      hozAlign: "left", width: "7%", editable: false},
  ]

  visibleColumns:any = {
    "SortDate": true,
    "Date": true,
    "Rev": true,
    "mRev": true,
    "CPT": true,
    "Description": true,
    "Units": true,
    "AdjUnits": false,
    "Billed": true,
    "Adjusted": true,
    "PreExAdjAmt": false,
    "PreExExplanation": false,
    "BillPageNo": false,
    "BillLineNo": false,
    "APE": true,
    "A/R": true,
    "A/R Desc": true,
    "A/E": true,
    "A/E Desc": true,
    "App/R": true,
    "App/R Desc": true,
    "App/E": true,
    "App/E Desc": true,
    "App Decision": true
  }

  // Select all line items, deselect all line items. Top table checkbox column header
  // value. Note: not used yet.
  allSelected = false;

  // The currently selected line item ids from the child line item grid component.
  selectedLineItemIds: any [] = [];

  refreshLineItemData$ = new Subject<void>();

  // Single Choice Dialog ref
  dialogRef?: MatDialogRef<any>;

  @ViewChild('topLevelWrapper') topLevelWrapper!: ElementRef;

  // Itemized Line Item Grid Component Reference
  @ViewChild('itemizedLineItemGrid')
  itemizedLineItemGridComponent?: ItemizedLineItemGridComponent;

  @ViewChild('itemizedRevisionRevenueCodeSummary')
  itemizedRevisionRevenueCodeSummaryComponent?: ItemizedRevisionRevenueCodeSummaryComponent;

  @ViewChild('ub04ReviewComponent')
  ub04ReviewComponent?: Ub04ReviewComponent;

  // Fires when the user requests to close the itemized line item component.
  itemizedLineItemComponentClose: Subject<any> = new Subject<any>();

  componentHeightPX: number = 500;

  // Result object from Itemized Line Item Query.
  itemizedLineItems: any = {
    lineItems: []
  };
  noDataFound: boolean = true;

  constructor(
    private itemizedLineItemService: ItemizedLineItemService,
    private itemizedRevisionService: ItemizedRevisionService,
    protected snack: FennecSnackbarService,
    protected userProfileService: UserProfileService,
    public matDialog: MatDialog,
  ) {
    super();
    this.lineItemQueryFormGroup = this.createFormGroup();
    this.sortOrderFormGroup = this.createSortOrderFormGroup();
    this.descriptionFilterFormGroup = this.createDescriptionFilterFormGroup();

    // Builds the Itemized Line Item Editor Grid's column configuration.
    this.buildInitialGridColumnConfig();
   }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.refreshTableConfig();
      this.refreshLineItemData$.pipe(
        debounceTime(200),
        // takeUntil(this.refreshLineItemData$),
        takeUntil(this.destroyed$)
      ).subscribe(() => this.fetchLineItems());
      this.getItemizedRevisionInfoQuery();
      this.fetchLineItems();
    }, 0);

    this.serviceDatePicker?.selectedChange?.subscribe((d) => {
      if (d !== null) {
        if (this.isDateAServiceDate(d)) {
          this.setSelectedServiceDateString(this.dateToString(d));
          this.refreshLineItemData$.next();

        } else {
          this.setSelectedServiceDateString("ALL");
          this.refreshLineItemData$.next();
        }
      }
    });

    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.refreshLineItemData$.next();
      });
    }
  }

  ngOnInit(): void {
  }

  closeItemizedLineItemComponent() {
    this.itemizedLineItemComponentClose.next(null);
  }

  leftNavView(viewIdx: number) {
    this.leftNavViewIdx = viewIdx;
    // When moving back to 'general' view, always put the user back on the line item editor grid display.
    if (viewIdx === 0) {
      this.leftNavSelectedView(0);
    }
  }

  leftNavSelectedView(viewIdx: number) {
    this.leftNavSelectedViewIdx = viewIdx;
    if (viewIdx === 0) {
      setTimeout(() => {
        this.refreshTableConfig();
        this.itemizedLineItemGridComponent?.refreshTableDisplay();
      }, 50);
    }
    if (viewIdx === 1) {
      setTimeout(() => {
        this.refreshTableConfig();
        this.itemizedRevisionRevenueCodeSummaryComponent?.getRevenueCodeSummary();
      }, 50);
    }
    if (viewIdx === 2) {
      setTimeout(() => {
        this.refreshTableConfig();
        this.ub04ReviewComponent?.getUb04ReviewInfo();
      }, 50);
    }
  }

  // Formats individual date cells. This is where we highlight the dates of service in the calendar.
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      if (this.isDateAServiceDate(cellDate)) {
        return 'fennec-mat-calendar-highlight-date-cell';
      }

    }

    return '';
  };

  isDateAServiceDate(d: Date): boolean {
    return this.serviceDateStrings.includes(this.dateToString(d));
  }

  // Converts javascript date to YYYY-MM-DD.
  dateToString(d: Date): string {
    return d.toISOString().split('T')[0];
  }

  createFormGroup(): FormGroup {
    let fg: FormGroup =
      new FormGroup({
        serviceDateString: new FormControl("ALL"),
        revenueCodeString: new FormControl([]),
        mappedRevenueCodeString: new FormControl([]),
        reasonCodeFilter: new FormControl([]),
        explanationCodeFilter: new FormControl([]),
        adjusted: new FormControl("ALL"),
        apeAdjusted: new FormControl("ALL"),
        comments: new FormControl("NA"),
        reportExcludeString: new FormControl("ALL")
      });
    return fg;
  }

  createSortOrderFormGroup(): FormGroup {
    let fg: FormGroup =
      new FormGroup({
        sortOrder: new FormControl(0)
      });
    return fg;
  }

  createDescriptionFilterFormGroup(): FormGroup {
    let fg: FormGroup =
      new FormGroup({
        description: new FormControl()
      });
    return fg;
  }

  actionSubscription: Subscription;
  lineItemsSelected?: Subscription;
  revenueCodeSelected: Subscription;
  mappedRevenueCodeSelected: Subscription;
  refreshTableConfig() {

    this.setIliEditorMode(this.iliEditorMode);
    this.refreshLineItemData$.next();

    this.itemizedLineItemGridComponent?.lineItemActionRequested.subscribe((payload) => {
      // Note: payload returns an array of line items for future expansion
      if (payload.action === "APL") {
        this.onAppealAdjustment(payload.lineItemIds);
      }
      if (payload.action === "ADJ") {
        this.onAdjustment(payload.lineItemIds);
      }
      if (payload.action === "PRISTINE") {
        this.onPristine(payload.lineItemIds);
      }
      if(payload.action === "APE") {
        this.onApeDiary(payload.lineItemIds);
      }
      if(payload.action === "COMMENTS") {
        this.onViewComments(payload.lineItemIds);
      }
      if(payload.action === "REV_CODE") {
        this.onEdit(payload);
      }
    });

    this.lineItemsSelected?.unsubscribe();
    this.lineItemsSelected = this.itemizedLineItemGridComponent?.lineItemsSelected.subscribe((payload) => {
      this.selectedLineItemIds = payload;
    });

    this.revenueCodeSelected?.unsubscribe();
    this.revenueCodeSelected = this.itemizedRevisionRevenueCodeSummaryComponent?.revenueCodeSelected.subscribe((rc: string) => {
      this.clearAllFilters(false);
      this.setSelectedRevenueCodeString([rc]);
      this.leftNavSelectedViewIdx = 0;
      this.refreshLineItemData$.next();
    });

    this.mappedRevenueCodeSelected?.unsubscribe();
    this.mappedRevenueCodeSelected = this.itemizedRevisionRevenueCodeSummaryComponent?.mappedRevenueCodeSelected.subscribe((rc: string) => {
      this.clearAllFilters(false);
      this.setSelectedMappedRevenueCodeString([rc]);
      this.leftNavSelectedViewIdx = 0;
      this.refreshLineItemData$.next();
    });
  }

  setIliEditorMode(iliEditorMode:string) {
    // If the config is built, but the line item editor is available, but does not have the config loaded, set it
    // now.
    if (this.columnConfig != null && this.columnConfig.length > 0) {
      if (this.itemizedLineItemGridComponent?.isColumnConfigEmpty()) {
        this.itemizedLineItemGridComponent?.setColumnConfig(this.columnConfig, this.iliEditorMode);
      }
    }

    if (this.iliEditorMode === iliEditorMode) {
      return;
    }
    if (iliEditorMode === "APPEAL" && this.iliEditorMode !== "APPEAL") {
      this.lineItemQueryFormGroup.controls['adjusted'].setValue("TRUE");
    }
    this.iliEditorMode = iliEditorMode;

    // Changes to the iliEditorMode value may warrant changes in the grid column config. Rebuild the column config
    // from the ground up and then alert the component to reload the configuration data.
    this.buildInitialGridColumnConfig();
    this.itemizedLineItemGridComponent?.setColumnConfig(this.columnConfig, this.iliEditorMode);

    // Load/Reload the grid data after a short delay.
    setTimeout(() => {
      this.fetchLineItems();
    }, 50);
  }

  getSelectedServiceDateString() : string {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['serviceDateString'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return "ALL";
    }
  }

  getSelectedRevenueCodeStrings() : string [] {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['revenueCodeString'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return [];
    }
  }

  setSelectedRevenueCodeString(rc: string []) {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['revenueCodeString'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      sdfc.setValue(rc);
    }
  }

  getSelectedMappedRevenueCodeStrings() : string [] {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['mappedRevenueCodeString'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return [];
    }
  }

  setSelectedMappedRevenueCodeString(rc: string []) {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['mappedRevenueCodeString'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      sdfc.setValue(rc);
    }
  }
  getReasonCodeFilter() : string [] {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['reasonCodeFilter'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return [];
    }
  }

  getExplanationCodeFilter() : string [] {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['explanationCodeFilter'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return [];
    }
  }


  setSelectedServiceDateString(dateString: string) {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['serviceDateString'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      sdfc.setValue(dateString);
    }
  }

  getSelectedAdjustedString() : string {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['adjusted'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return "ALL";
    }
  }

  getSelectedSortOrder() : string {
    let sdfc: FormControl = this.sortOrderFormGroup.controls['sortOrder'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return "0";
    }
  }

  getSelectedReportExcludeString() : string {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['reportExcludeString'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return "ALL";
    }
  }

  getSelectedApeAdjustedString() : string {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['apeAdjusted'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return "ALL";
    }
  }

  getSelectedCommentsString() : string {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['comments'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return "NA";
    }
  }

  getDescriptionFilterValue() : string {
    let sdfc: FormControl = this.descriptionFilterFormGroup.controls['description'] as FormControl
    return sdfc.value;
  }

  fetchLineItems() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    // Note: the filter no may eventuall be removed. The implementation never really went anywhere. We just
    // pass up the parameters we need regardless of the filter no, so it's not really being used.
    this.filterNo = 0;
    if (this.getSelectedAdjustedString().toUpperCase() !== "ALL" ||
        this.getSelectedSortOrder() !== "0" ||
        this.getSelectedServiceDateString().toUpperCase() !== "ALL" ||
        this.getSelectedRevenueCodeStrings().length > 0 ||
        this.getSelectedMappedRevenueCodeStrings().length > 0 ||
        this.getSelectedApeAdjustedString().toUpperCase() !== "ALL" ||
        this.getSelectedCommentsString().toUpperCase() !== "NA" ||
        this.getSelectedReportExcludeString().toUpperCase() !== "ALL" ||
        this.getDescriptionFilterValue() !== null) {
          this.filterNo = 1;
    }
    this.performXFRequest({
      requestDescription: "Get line items",
      requestFn: this.itemizedLineItemService.getItemizedLineItemQuery,
      fnParams: [
        parseInt(this.itemizedRevisionId, 10),
        this.filterNo,
        parseInt(this.getSelectedSortOrder()),
        this.getDescriptionFilterValue(),
        this.getSelectedServiceDateString(),
        this.getSelectedRevenueCodeStrings(),
        this.getSelectedMappedRevenueCodeStrings(),
        this.getReasonCodeFilter(),
        this.getExplanationCodeFilter(),
        this.getSelectedAdjustedString(),
        this.getSelectedApeAdjustedString(),
        this.getSelectedCommentsString(),
        this.getSelectedReportExcludeString(),
        first,
        pageSize
      ],
      cancelRequest$: this.refreshLineItemData$.asObservable(),
      onResponse: (response: PagedResponse<any>) => {
        if (!response.hasErrors) {
          this.itemizedLineItems = response.data;
          if (this.itemizedLineItems.lineItems.length <= 0) {
            this.noDataFound = true;
          } else {
            this.noDataFound = false;
          }
          this.paginator.pageIndex = response?.pageNo;
          this.paginator.length = response?.totalRowCount;
          this.formatLineItems();
          setTimeout(() => {
            this.itemizedLineItemGridComponent?.setLineItemData(this.itemizedLineItems.lineItems);
          }, 500);

        }
      },
      onError: (errString) => {
        this.snack.showErrorSnack(errString);
      }
    });
  }

  getItemizedRevisionInfoQuery() {
    if (parseInt(this.itemizedRevisionId) < 0) {
      return;
    }
    this.itemizedRevisionService.getItemizedRevisionInfo(parseInt(this.itemizedRevisionId, 10)).subscribe((response: any) => {
      if (response.hasErrors) {
        this.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.miCaseId = response.data.miCaseId;
        this.itemizedId = response.data.itemizedId;
        this.lineItemCount = response.data.numberOfLineItems;
        if (response.data.appeal) {
          this.setIliEditorMode("APPEAL");
        } else {
          this.setIliEditorMode("CLAIM_REVIEW");
        }
        this.linkedItemizedUb04Id =
          response.data.linkedItemizedUb04Id !== null && response.data.linkedItemizedUb04Id !== undefined
          ? response.data.linkedItemizedUb04Id : null;
        // Only populate the service date strings ONCE. These should really not change once they are loaded.
        // We may have to revisit this.
        if (this.serviceDateStrings.length <= 0) {
          this.serviceDateStrings = ["ALL"];
          response.data.serviceDateStrings.forEach((sds: string) => {
            this.serviceDateStrings.push(sds);
          });
          this.setDatePickerToFirstServiceDate();
        }
        // Revenue Code Strings
        response.data.revenueCodeSummaryLines.forEach((rcObj: any) => {
          this.revenueCodeStrings.push(rcObj.revenueCode);
        });
        // Mapped Revenue Code Strings
        response.data.mappedRevenueCodeSummaryLines.forEach((rcObj: any) => {
          this.mappedRevenueCodeStrings.push(rcObj.revenueCode);
        });
        this.reasonCodeFilterOptions = response.data.adjustmentReasonCodes;
        this.explanationCodeFilterOptions = response.data.adjustmentExplanationCodes;
        // Summary Totals
        this.totalBilledAmount = response.data.totalBilledAmount;
        this.totalAdjustedAmount = response.data.totalAdjustedAmount;
        this.totalUnits = response.data.totalUnits;
        this.totalAdjustedUnits = response.data.totalAdjustedUnits;
        // Other
        this.apeProcessed = response.data.apeProcessed;
      }
    });
  }

  getReasonCodeFilterOptions() {

  }

  getExplanationCodeFilterOptions() {

  }

  setDatePickerToFirstServiceDate() {
    if (this.serviceDateStrings.length > 1) {
      if (this.serviceDatePicker !== null && this.serviceDatePicker !== undefined) {
        this.serviceDatePicker.activeDate = new Date(this.serviceDateStrings[1]);
        this.serviceDatePicker.updateTodaysDate();
      }
    }
  }

  // Roll line items through some display mutations on fields we'd like to see presented differently
  // on the grid.
  formatLineItems() {
    // Set all line items to not selected
    this.itemizedLineItems.lineItems.forEach((li:any) => {
      li['selected'] = false;
      li['scrollToRow'] = false;
      li['serviceDateStringFormatted'] = this.formatDate(li['serviceDateString']);
      li['apeAdjustedFormatted'] = li['apeAdjusted'] ? "Y" : null;
    });
  }

  selectLineItem(id:number) {
    let li = this.getItemizedLineItem(id);
    if (li !== null) {
      li["selected"] = !li["selected"];
    }
  }

  selectAllLineItems() {
    this.allSelected = !this.allSelected;
    this.itemizedLineItems.lineItems.forEach((li:any) => {
      li["selected"] = this.allSelected;
    });
  }

  onAdjustment(ids: number []) {
    if (ids.length === 0) {
      return;
    }

    let li = this.getItemizedLineItem(ids[0]);
    if (li === null || li === undefined) {
      return;
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ids : ids,
      liDescription: li.description,
      liServiceDateString : this.formatDate(li.serviceDateString),
      adjustedUnits: li.adjustedUnits,
      adjustedAmount: li.adjustedAmount,
      adjustmentExplanationCode: li.adjustmentExplanationCode,
      adjustmentReasonCode: li.adjustmentReasonCode,
      adjustmentReasonCodeDescription: li.adjustmentReasonCodeDescription,
      adjustmentExplanationCodeDescription: li.adjustmentExplanationCodeDescription,
      billedAmount: li.billedAmount
    };
    const dialogRef = this.matDialog.open(ItemizedLineItemAdjDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        if (result !== null && result !== undefined && result.ids.length > 0) {
          this.itemizedLineItemGridComponent?.setScrollToLineItemId(result.ids[0]);
        }
        this.refreshLineItemData$.next();
        this.getItemizedRevisionInfoQuery();
      }
    });
  }

  onAppealAdjustment(ids: number []) {
    if (ids.length === 0) {
      return;
    }

    // On appeal adjustments, all of the line items requested must have a LINE_ITEM_ADJUSTMENT already on it. If
    // not, then the appeal adjustment cannot be made.
    for (let idx:number = 0; idx < ids.length; idx++) {
      let cli = this.getItemizedLineItem(ids[idx]);
      if (cli === null || cli === undefined) {
        return;
      }
      if (cli.adjustmentReasonCode === null || cli.adjustmentExplanationCode === undefined) {
        this.showErrorSnack("Only adjusted line items can have appeal adjustments (reason/explanation) applied!");
        return;
      }
    }

    // Get the first line item requested for visual display purposes in the dialog.
    let li = this.getItemizedLineItem(ids[0]);
    if (li === null || li === undefined) {
      return;
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ids : ids,
      liDescription: li.description,
      liServiceDateString : this.formatDate(li.serviceDateString),
      adjustedUnits: li.adjustedUnits,
      adjustedAmount: li.adjustedAmount,
      adjustmentExplanationCode: li.adjustmentExplanationCode,
      adjustmentReasonCode: li.adjustmentReasonCode,
      adjustmentReasonCodeDescription: li.adjustmentReasonCodeDescription,
      adjustmentExplanationCodeDescription: li.adjustmentExplanationCodeDescription,
      appealExplanationCode: li.appealExplanationCode,
      appealReasonCode: li.appealReasonCode,
      appealReasonCodeDescription: li.appealReasonCodeDescription,
      appealExplanationCodeDescription: li.appealExplanationCodeDescription,
      appealDecision: li.appealDecision,
      billedAmount: li.billedAmount
    };
    const dialogRef = this.matDialog.open(ItemizedLineItemAppealAdjDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        if (result !== null && result !== undefined && result.ids.length > 0) {
          this.itemizedLineItemGridComponent?.setScrollToLineItemId(result.ids[0]);
        }
        this.refreshLineItemData$.next();
        this.getItemizedRevisionInfoQuery();
      }
    });
  }

  onPristine(ids: number []) {
    if (ids.length === 0) {
      return;
    }

    let li = this.getItemizedLineItem(ids[0]);
    if (li === null || li === undefined) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    if (ids.length === 1) {
      dialogConfig.data.question = "Set line item to pristine?";
    } else {
      dialogConfig.data.question = `Set (${ids.length}) line items to pristine?`
    };
    this.dialogRef = this.matDialog.open(SingleChoiceDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.itemizedLineItemService.putItemizedLineItemPristine(ids).subscribe(response => {
          if (response.hasErrors) {
            this.showErrorSnack(response.consolidatedErrorMessage);
          } else{
            if (result !== null && result !== undefined && ids.length > 0) {
              this.itemizedLineItemGridComponent?.setScrollToLineItemId(ids[0]);
            }
            this.refreshLineItemData$.next();
            this.getItemizedRevisionInfoQuery();
            this.snack.showSuccessSnack(`Successfully Updated!`);
          }
        })
      }
    });

  }

  private getItemizedLineItem(id: number):any {
    let li = null;
    for (let idx:number = 0; idx < this.itemizedLineItems.lineItems.length; idx++) {
      if (this.itemizedLineItems.lineItems[idx].id === id) {
        li = this.itemizedLineItems.lineItems[idx];
        break;
      }
    }
    return li;
  }

  formatDate(dateString: string): string {
    return DateUtil.getDisplayDateYYYY(dateString);
  }

  // Drop Down list changed - service date selector
  serviceDateSelectionChanged(sds: any) {
    // Sync with Mat calendar selection
    if (this.serviceDatePicker !== null && this.serviceDatePicker !== undefined) {
      if (sds !== "ALL") {
        let selectedDate: Date = new Date(this.getSelectedServiceDateString());
        // Handles timezone offsets universally so we can feed the calendar date picker the right
        // date without timezone offset funnyness
        selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset())
        this.serviceDatePicker.selected = selectedDate;
        this.serviceDatePicker.activeDate = selectedDate;
        this.serviceDatePicker.updateTodaysDate();
      } else {
        this.serviceDatePicker.selected = null;
        this.setDatePickerToFirstServiceDate();
        this.serviceDatePicker.updateTodaysDate();
      }
    }
    this.refreshLineItemData$.next();
  }

  revenueCodeSelectionChanged(rcs: any) {
    this.refreshLineItemData$.next();
  }

  mappedRevenueCodeSelectionChanged(rcs: any) {
    this.refreshLineItemData$.next();
  }
  reasonCodeFilterChanged(rcs: any) {
    this.refreshLineItemData$.next();
  }
  explanationCodeFilterChanged(rcs: any) {
    this.refreshLineItemData$.next();
  }

  adjustedFilterChanged(adj: any) {
    this.refreshLineItemData$.next();
  }

  sortOrderChanged(adj: any) {
    this.refreshLineItemData$.next();
  }

  apeAdjustedFilterChanged(adj: any) {
    this.refreshLineItemData$.next();
  }

  commentsFilterChanged(adj: any) {
    this.refreshLineItemData$.next();
  }

  reportExcludeFilterChanged(adj: any) {
    this.refreshLineItemData$.next();
  }

  onMultiSelectAdj() {
    this.onAdjustment(this.selectedLineItemIds);
  }

  onMultiSelectAppealAdj() {
    this.onAppealAdjustment(this.selectedLineItemIds);
  }

  onMultiSelectPristine() {
    this.onPristine(this.selectedLineItemIds);
  }

  onDescriptionSearch() {
    this.refreshLineItemData$.next();
  }

  clearDescriptionFilter() {
    let fc: FormControl = this.descriptionFilterFormGroup.controls['description'] as FormControl;
    fc.setValue(null);
    this.paginator.pageIndex = 0;
    this.refreshLineItemData$.next();
  }

  clearAllFilters(requery = true) {
    let fc: FormControl = this.descriptionFilterFormGroup.controls['description'] as FormControl;
    fc.setValue(null);
    fc = this.lineItemQueryFormGroup.controls['serviceDateString'] as FormControl;
    fc.setValue("ALL");
    fc = this.lineItemQueryFormGroup.controls['revenueCodeString'] as FormControl;
    fc.setValue([]);
    fc = this.lineItemQueryFormGroup.controls['mappedRevenueCodeString'] as FormControl;
    fc.setValue([]);
    fc = this.lineItemQueryFormGroup.controls['adjusted'] as FormControl;
    fc.setValue("ALL");
    fc = this.lineItemQueryFormGroup.controls['apeAdjusted'] as FormControl;
    fc.setValue("ALL");
    fc = this.lineItemQueryFormGroup.controls['comments'] as FormControl;
    fc.setValue("NA");
    fc = this.lineItemQueryFormGroup.controls['reportExcludeString'] as FormControl;
    fc.setValue("ALL");
    this.paginator.pageIndex = 0;
    if (this.serviceDatePicker !== null && this.serviceDatePicker !== undefined) {
      this.serviceDatePicker.selected = null;
      this.setDatePickerToFirstServiceDate();
      this.serviceDatePicker.updateTodaysDate();
    }
    // Clear the sorting settings on the grid.
    this.itemizedLineItemGridComponent?.clearTableSort();
    if (requery) {
      this.refreshLineItemData$.next();
    }
  }

  clearRevenueCodeFilter(requery = true) {
    let fc: FormControl;
    fc = this.lineItemQueryFormGroup.controls['revenueCodeString'] as FormControl;
    fc.setValue([]);
    if (requery) {
      this.refreshLineItemData$.next();
    }
  }

  clearMappedRevenueCodeFilter(requery = true) {
    let fc: FormControl;
    fc = this.lineItemQueryFormGroup.controls['mappedRevenueCodeString'] as FormControl;
    fc.setValue([]);
    if (requery) {
      this.refreshLineItemData$.next();
    }
  }
  clearReasonCodeFilter(requery = true) {
    let fc: FormControl;
    fc = this.lineItemQueryFormGroup.controls['reasonCodeFilter'] as FormControl;
    fc.setValue([]);
    if (requery) {
      this.refreshLineItemData$.next();
    }
  }
  clearExplanationCodeFilter(requery = true) {
    let fc: FormControl;
    fc = this.lineItemQueryFormGroup.controls['explanationCodeFilter'] as FormControl;
    fc.setValue([]);
    if (requery) {
      this.refreshLineItemData$.next();
    }
  }

  onApeDiary = (ids:number[]) => {
    const id = ids[0];
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      itemizedLineItemId: id
    }
    this.dialogRef = this.matDialog.open(ItemizedApeRuleEventDiaryComponent, matDialogConfig);
  }

  onViewComments = (ids:number[]) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      mode: "ITEMIZED_LINE_ITEM",
      relatedId: ids[0],
      lineItemIds: ids
    }
    this.dialogRef = this.matDialog.open(CommentListDialogComponent, matDialogConfig);
    this.dialogRef.afterClosed().subscribe(result => {
      if (ids.length > 0) {
        this.itemizedLineItemGridComponent?.setScrollToLineItemId(ids[0]);
      }
      this.refreshLineItemData$.next();
    });
  }

  onEdit = (data:any) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      mappedRevenueCode: data.mappedRevenueCode,
      mappedRevenueCodeDescription: data.mappedRevenueCodeDescription,
      lineItemIds: data.lineItemIds,
      description: data.description,
      preExAdjustedAmount: data.preExAdjustedAmount,
      preExExplanation: data.preExExplanation,
      billPageNo: data.billPageNo,
      reportExclude: data.reportExclude
    }
    this.dialogRef = this.matDialog.open(ItemizedLineItemEditDialogComponent, matDialogConfig);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined && result.ids.length > 0) {
        this.itemizedLineItemGridComponent?.setScrollToLineItemId(result.ids[0]);
      }
      this.refreshLineItemData$.next();
    });
  }

  onMultiSelectEdit = () => {
    let firstLineItem: any = null;
    this.itemizedLineItems.lineItems.forEach((element: any)  => {
      if (element.id === this.selectedLineItemIds[0]) {
        firstLineItem = element;
      }
    });
    if (firstLineItem === null) {
      return;
    }

    const mappedRevCode = firstLineItem.mappedRevenueCode;
    const mappedRevCodeDescription = firstLineItem.mappedRevenueCodeDescription;
    const lineItemDescription = firstLineItem.description;
    const preExAdjustedAmount = firstLineItem.preExAdjustedAmount;
    const preExExplanation = firstLineItem.preExExplanation;
    const billPageNo = firstLineItem.billPageNo;
    const reportExclude = firstLineItem.reportExclude;

    const data = {
      lineItemIds: this.selectedLineItemIds,
      mappedRevenueCode: mappedRevCode,
      mappedRevCodeDescription: mappedRevCodeDescription,
      description: lineItemDescription,
      preExAdjustedAmount: preExAdjustedAmount,
      preExExplanation: preExExplanation,
      billPageNo: billPageNo,
      reportExclude: reportExclude
    }

    this.onEdit(data);
  }

  toggleSideNav = () => {
    this.navigationOpen = !this.navigationOpen;
    if (this.leftNavSelectedViewIdx === 0) {
      setTimeout(() => {
        this.itemizedLineItemGridComponent?.refreshTableDisplay();
      }, 50);
    }
  }

  // used to dynamically add and remove columns from line item editor
  toggleColumn = (title:string) => {
    this.visibleColumns[title] = !this.visibleColumns[title];
    this.reloadGrid();
    localStorage.setItem("itemizedLineEditorColumns", JSON.stringify(this.visibleColumns));
  }

  reloadGrid = () => {
    this.orderColumnConfig();
    this.itemizedLineItemGridComponent?.setColumnConfig(this.columnConfig, this.iliEditorMode);
    this.itemizedLineItemGridComponent?.refreshTableDisplay();
  }

  buildInitialGridColumnConfig() {
    this.columnConfig = this.defaultColumnConfig;

    // Load up user's selected visible columns from local storage.
    const ls = localStorage.getItem("itemizedLineEditorColumns");
    if (ls) {
      let lsVisibleColumns = JSON.parse(ls);
      for (const key in lsVisibleColumns) {
        this.visibleColumns[key] = lsVisibleColumns[key];
      }
      this.orderColumnConfig();
    }
  }

  orderColumnConfig = () => {
    const order = ["SortDate", "Date", "Rev", "mRev", "CPT", "Description", "Units",
      "AdjUnits", "Billed", "Adjusted", "PreExAdjAmt", "PreExExplanation", "BillPageNo", "BillLineNo",
      "APE", "A/R", "A/R Desc", "A/E", "A/E Desc",
      "App/R", "App/R Desc", "App/E", "App/E Desc", "App Decision"];
    const resultList:any[] = [];


    order.forEach((title) => {
      if (this.visibleColumns[title]) {
        resultList.push(this.getDefaultColumnByTitle(title));
      }
    })

    this.columnConfig = resultList;
  }

  checkIfColumnIsVisibleByTitle = (title:string) => {
    const modifiedTitle:string = title.replace(" ", "");
    modifiedTitle.replace("/", "");
    return this.visibleColumns[modifiedTitle];
  }

  getDefaultColumnByTitle = (title:string) => {
    return this.defaultColumnConfig.find(x => x.title === title);
  }

  getVisibleColumnKeys = () => {
    return Object.keys(this.visibleColumns);
  }

  onMultiComment = () => {
    let mode = "MULTI";

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "65vw";
    matDialogConfig.data = {
      mode,
      comment: null,
      relationalType: "ITEMIZED_LINE_ITEM",
      relatedId: null,
      relatedIdList: this.selectedLineItemIds
    };

    const dialogRef = this.matDialog.open(AddCommentComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      if (this.selectedLineItemIds.length > 0) {
        this.itemizedLineItemGridComponent?.setScrollToLineItemId(this.selectedLineItemIds[0]);
      }
      this.refreshLineItemData$.next();
    })
  }

  userSelectItemsPerPage(ps: number) {
    this.userDefaultPageSize = ps;
    this.defaultPageSize = ps;
    // Let the screen re-render after setting the userDefaultPageSize etc. before requesting a new line item
    // query. This was done to help settle in the Tabulator JS rendering which involves a max call stack error
    // during window resizing in Chrome browsers.
    setTimeout(() => {
      this.refreshLineItemData$.next();
    }, 400);
  }

}
