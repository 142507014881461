import { AfterViewInit, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivityLogDialogConfig, FennecSnackbarService, Logger } from 'xf-common';
import { ActivityLogService } from '../activity-log.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { registerCustomQueryHandler } from 'puppeteer';

@Component({
  selector: 'app-activity-log-json-dialog',
  templateUrl: './activity-log-json-dialog.component.html',
  styleUrls: ['./activity-log-json-dialog.component.scss']
})
export class ActivityLogJsonDialogComponent implements AfterViewInit, OnInit {

  jsonContent: any;
  title: string;

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ActivityLogJsonDialogComponent>,
    private snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.jsonContent = data.jsonContent;
    this.title = data.title;
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {

  }


  close() {
    const retObj = {
      confirm: false
    }
    this.dialogRef.close(retObj);
  }


}
