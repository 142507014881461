<mat-dialog-content>
  <div class="dialog-header alt-theme">
    <h1 mat-dialog-title>Edit Procedure Code Information</h1>
    <!-- <button
      mat-button
      mat-stroked-button
      class="ub04-icon-btn"
      tabindex="-1"
      (click)="onCancel()"
      matTooltip="Close Dialog">
      <mat-icon>close</mat-icon>
    </button> -->
    <mat-form-field>
      <mat-label>Sort</mat-label>
      <mat-select
        [(ngModel)]="sort"
        [disabled]="!canSort"
        (selectionChange)="fetchData()"
      >
        <!-- <mat-option 
          *ngFor="let option of sortOptions"
          [value]="option"
          >
          {{ option }}
        </mat-option> -->
        <mat-option 
          [value]="'createdDate'"
          >
          Created Date
        </mat-option>

        <mat-option 
          [value]="'serviceDate'"
          >
          Service Date
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="clear: both;"></div>
  <div
    [formGroup]="formGroup"
    class="content-wrapper alt-theme">
    <ng-container formArrayName="procedureCodes">
        <ng-container
          *ngFor="let rcRow of procedureCodes.controls; let i = index">
            <div
              class="lesson-form-row"
              [formGroup]="getProcedureCodeFormGroup(i)"
              [ngStyle]="getRowDisplayStyle(i)">
              <mat-icon
                class="ub04-field-status-icon"
                style={{getIconStyle(i)}}
              >
                {{ getDeleteRowOrBlankValueByIdx(i) ? "remove_circle_outline" : rcRow.valid ? "check_circle" : "error" }}
              </mat-icon>

              <!-- <mat-form-field
                appearance="outline"
                [style.width.px]=160
                >
                <mat-label>{{ i === 0 ? 'Primary'  : 'Other' }} Procedure Code</mat-label>
                <input
                  matInput
                  formControlName="procedureCode"
                  placeholder="Procedure Code">
              </mat-form-field> -->

              <lib-xf-form-field
                appearance="outline"
                labelText="{{ i === 0 ? 'Primary'  : 'Other' }} Procedure Code"
                formControlName="procedureCode"
                [width]=160
                [maxLength]=8
                [warningDifference]="2"
              ></lib-xf-form-field>


              <mat-form-field
                appearance="fill"
                [style.width.px]=500>
                <mat-label>Description</mat-label>
                <input
                  matInput
                  formControlName="description"
                  placeholder="Description">
              </mat-form-field>

              <!-- <mat-form-field
                appearance="outline"
                >
                <mat-label>Service Date</mat-label>
                <input
                  matInput
                  formControlName="serviceDate"
                  placeholder="(MM/DD/YYYY)">
              </mat-form-field> -->

              <lib-xf-form-field
                appearance="outline"
                labelText="Service Date"
                formControlName="serviceDate"
                hint="(MM/DD/YYYY)"
                expectedPattern="MM/DD/YYYY"
              ></lib-xf-form-field>

              <button
                *ngIf="!getDeleteRowByIdx(i); else undoDelete"
                mat-flat-button
                matTooltip="Delete"
                class="ub04-icon-btn"
                (click)="onDelete(i)">
                <mat-icon>delete</mat-icon>
              </button>

              <ng-template #undoDelete>
                <button
                  #undoDelete
                  mat-flat-button
                  matTooltip="Cancel Delete"
                  class="ub04-icon-btn"
                  (click)="onDelete(i)">
                  <mat-icon>restore_from_trash</mat-icon>
                </button>
              </ng-template>
            </div>
        </ng-container>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">

  <div class="ub04-dialog-add-btn">
    <button
      mat-button
      mat-stroked-button
      matTooltip="Add 'Other' Procedures"
      class="ub04-icon-btn"
      (click)="onAddProcedureCodeLine()">
      <mat-icon>add_circle</mat-icon>
      Add Procedure Code
    </button>
  </div>

  <div>
    <mat-checkbox
      class="mark-complete-checkbox"
      [checked]="statusTrackComplete"
      (change)="toggleStatusTrackComplete()">
      Mark Complete
    </mat-checkbox>
    <button
      mat-flat-button
      [disabled]="formGroup.invalid"
      (click)="onSubmit()"
      color="accent">
        <mat-icon>save</mat-icon> Save
    </button>
    <button
      mat-button
      mat-stroked-button
      (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
  </div>
</mat-dialog-actions>
